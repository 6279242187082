import { del, get, post, put } from "../api_helper"
import * as url from "../url_helper"

const postApiUpdateProfile = data => {
  var formData = new FormData()
  formData.append("name", data.name)
  formData.append("photo", data.photo)
  formData.append("delete_photo", data.delete_photo)
  

  return post(url.POST_UPDATE_PROFILE, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

const postApiUpdatePassword = data => {
  var formData = new FormData()
  formData.append("password_current", data.password_current)
  formData.append("password_new", data.password_new)

  return post(url.POST_UPDATE_PASSWORD, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

const postApiUpdateEmail = data => {
  var formData = new FormData()
  formData.append("email", data.email)

  return post(url.POST_UPDATE_EMAIL, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export { postApiUpdateProfile, postApiUpdatePassword, postApiUpdateEmail }
