/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputDateMask from "components/Input/InputDateMask"
import InputTextArea from "components/Input/InputTextArea"
import InputNumber from "components/Input/InputNumber"
import InputRadio from "components/Input/InputRadio"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { canSubmitRegister, scrollTop } from "helpers/utils_helper"

const TabFormEmploymentHistory = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
  reset = false,
  onResetFalse = () => {},
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (reset) {
      setError("")
      onResetFalse()
    }
  }, [reset])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      institution_a: dataDetail?.r07_institution_a || "",
      address_a: dataDetail?.r07_address_a || "",
      phone_a: dataDetail?.r07_phone_a || "",
      title_a: dataDetail?.r07_title_a || "",
      date_start_a: dataDetail !== null && dataDetail?.r07_date_start_a !== "0000-00-00" ? dataDetail?.r07_date_start_a : "",
      date_end_a: dataDetail !== null && dataDetail?.r07_date_end_a !== "0000-00-00" ? dataDetail?.r07_date_end_a : "",
      category_a: dataDetail?.r07_category_a || "kbumn",
      other_a: dataDetail?.r07_other_a || "",
      job_description_a: dataDetail?.r07_job_description_a || "",

      institution_b: dataDetail?.r07_institution_b || "",
      address_b: dataDetail?.r07_address_b || "",
      phone_b: dataDetail?.r07_phone_b || "",
      title_b: dataDetail?.r07_title_b || "",
      date_start_b: dataDetail !== null && dataDetail?.r07_date_start_b !== "0000-00-00" ? dataDetail?.r07_date_start_b : "",
      date_end_b: dataDetail !== null && dataDetail?.r07_date_end_b !== "0000-00-00" ? dataDetail?.r07_date_end_b : "",
      category_b: dataDetail?.r07_category_b || "kbumn",
      other_b: dataDetail?.r07_other_b || "",
      job_description_b: dataDetail?.r07_job_description_b || "",
    },

    onSubmit: values => {
      values = {
        ...values,
        date_start_a: values.date_start_a,
        date_end_a: values.date_end_a,
        date_start_b: values.date_start_b,
        date_end_b: values.date_end_b,
      }

      if (canSubmitRegister(dataDetail?.status)) {
        if (!loading) postBeasiswaRegister(values)
      } else {
        onNext()
      }
    },
  })

  const postBeasiswaRegister = async data => {
    try {
      setLoading(true)
      const response = await postAPIBeasiswaRegister(data, "07")
      setLoading(false)
      if (response.status === "SUCCESS") {
        onSuccess(response.message)
        onNext()
      } else {
        scrollTop()
        setError(response.message)
        
      }
    } catch (error) {
      setLoading(false)
      scrollTop()
      setError("Something went wrong, please try again")
     
    }
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <p className="card-title-desc">
          Provide details of your work experience since completing your
          undergraduate degree starting with your current or most recent
          position. Include additional examples or details on an additional page
          as necessary.
        </p>

        <Row>
          <Col md={6}>
            {/* Name of Institution */}
            <div className="mb-3">
              <InputText
                label="Name of Institution"
                name="institution_a"
                placeholder="Name of Institution"
                value={_formik.values.institution_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Full Address */}
            <div className="mb-3">
              <InputTextArea
                label="Full Address"
                name="address_a"
                placeholder="Full Address"
                value={_formik.values.address_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Telephone Number */}
            <div className="mb-3">
              <InputText
                label="Telephone Number"
                name="phone_a"
                placeholder="Telephone Number (include code area)"
                value={_formik.values.phone_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Position Title */}
            <div className="mb-3">
              <InputText
                label="Position Title "
                name="title_a"
                placeholder="Position Title"
                value={_formik.values.title_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Start Date */}
            <div className="mb-3">
              <InputDateMask
                label="Start Date"
                name="date_start_a"
                mask="99/99/9999"
                placeholder="End Date (DD/MM/YYYY)"
                value={_formik.values.date_start_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* End Date */}
            <div className="mb-3">
              <InputDateMask
                label="End Date"
                name="date_end_a"
                mask="99/99/9999"
                placeholder="End Date (DD/MM/YYYY)"
                value={_formik.values.date_end_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <div className={_formik.values.category_a === "other" ? "mb-2" : "mb-3"}>
          <InputRadio
            name="category_a"
            label="Employment Category?"
            value={_formik.values.category_a || ""}
            onChange={_formik.handleChange}
            options={[
              {
                label: "KBUMN",
                value: "kbumn",
              },
              {
                label: "BUMN",
                value: "bumn",
              },
              {
                label: "Other",
                value: "other",
              },
            ]}
          />
        </div>

        {_formik.values.category_a === "other" && (
          <div className="mb-3">
            <InputText
              name="other_a"
              placeholder=""
              value={_formik.values.other_a || ""}
              onChange={_formik.handleChange}
              onBlur={_formik.handleBlur}
            />
          </div>
        )}

        <div className="mb-3">
          <InputTextArea
            label="Describe your job duties and accomplishments. Comment on supervisory responsibilities, as appropriate."
            name="job_description_a"
            placeholder=""
            value={_formik.values.job_description_a || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <hr />

        <Row>
          <Col md={6}>
            {/* Name of Institution */}
            <div className="mb-3">
              <InputText
                label="Name of Institution"
                name="institution_b"
                placeholder="Name of Institution"
                value={_formik.values.institution_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Full Address */}
            <div className="mb-3">
              <InputTextArea
                label="Full Address"
                name="address_b"
                placeholder="Full Address"
                value={_formik.values.address_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Telephone Number */}
            <div className="mb-3">
              <InputText
                label="Telephone Number"
                name="phone_b"
                placeholder="Telephone Number (include code area)"
                value={_formik.values.phone_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Position Title */}
            <div className="mb-3">
              <InputText
                label="Position Title "
                name="title_b"
                placeholder="Position Title"
                value={_formik.values.title_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Start Date */}
            <div className="mb-3">
              <InputDateMask
                label="Start Date"
                name="date_start_b"
                mask="99/99/9999"
                placeholder="End Date (DD/MM/YYYY)"
                value={_formik.values.date_start_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* End Date */}
            <div className="mb-3">
              <InputDateMask
                label="End Date"
                name="date_end_b"
                mask="99/99/9999"
                placeholder="End Date (DD/MM/YYYY)"
                value={_formik.values.date_end_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <div className={_formik.values.category_b === "other" ? "mb-2" : "mb-3"}>
          <InputRadio
            name="category_b"
            label="Employment Category?"
            value={_formik.values.category_b || ""}
            onChange={_formik.handleChange}
            options={[
              {
                label: "KBUMN",
                value: "kbumn",
              },
              {
                label: "BUMN",
                value: "bumn",
              },
              {
                label: "Other",
                value: "other",
              },
            ]}
          />
        </div>

        {_formik.values.category_b === "other" && (
          <div className="mb-3">
            <InputText
              name="other_b"
              placeholder=""
              value={_formik.values.other_b || ""}
              onChange={_formik.handleChange}
              onBlur={_formik.handleBlur}
            />
          </div>
        )}

        <div className="mb-3">
          <InputTextArea
            label="Describe your job duties and accomplishments. Comment on supervisory responsibilities, as appropriate."
            name="job_description_b"
            placeholder=""
            value={_formik.values.job_description_b || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <hr />

        <div className="d-flex justify-content-end">
          {onPrev && (
            <Link
              to="#"
              className={`btn btn-primary btn-block me-2 ${loading ? 'disabled' : ''}`}
              onClick={onPrev}
            >
              Previous
            </Link>
          )}

          <button className="btn btn-primary btn-block" type="submit">
            {loading ? <Spinner size="sm" /> : "Next"}
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TabFormEmploymentHistory
