/* eslint-disable react/prop-types */

import React, { useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import DivText from "components/Input/DivText"

const TabPreviewFormProposedUniversity = ({
  dataDetail = null,
}) => {
  return (
    <React.Fragment>
      <Form>
        <h4 className="mb-3 font-size-18">{"PROPOSED UNIVERSITY"}</h4>

        <Row>
          <Col md={6}>
            {/* Name of University */}
            <div className="mb-3">
              <DivText
                label="Name of University"
                content={dataDetail?.r12_university || "-"}
              />
            </div>

            {/* Faculty / Departement */}
            <div className="mb-3">
              <DivText
                label="Faculty / Departement"
                content={dataDetail?.r12_faculty || "-"}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Complete Address */}
            <div className="mb-3">
              <DivText
                label="Complete Address"
                content={dataDetail?.r12_address || "-"}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className="mb-3">
              <DivText
                label="Have you requested admission to this university?"
                content={dataDetail?.r12_request_admission || "-"}
              />
            </div>
            <div className="mb-2">
              <DivText
                label="Are you receiving any funding support?"
                content={dataDetail?.r12_receive_funding || "-"}
                mb="2px"
              />
            </div>
            <div className="mb-3">
              <DivText content={dataDetail?.r12_support_type || "-"} />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <DivText
                label="Have you received an admission from the university?"
                content={dataDetail?.r12_receive_admission || "-"}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {/* Contact Person */}
            <div className="mb-3">
              <DivText
                label="Contact Person"
                content={dataDetail?.r12_contact_person || "-"}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Email */}
            <div className="mb-3">
              <DivText label="Email" content={dataDetail?.r12_email || "-"} />
            </div>
          </Col>
        </Row>

        <div className="mb-3">
          <DivText
            label="In 150 words or less, please describe why you choose this university."
            content={dataDetail?.r12_reason || "-"}
          />
        </div>

        <hr />
      </Form>
    </React.Fragment>
  )
}

export default TabPreviewFormProposedUniversity
