/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import React, { useEffect, useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"

import InputTextArea from "components/Input/InputTextArea"
import DivText from "components/Input/DivText"

const TabPreviewFormFieldOfStudy = ({ dataDetail = null }) => {
  return (
    <React.Fragment>
      <Form>
        <h4 className="mb-3 font-size-18">{"FIELD OF STUDY"}</h4>
        <Row>
          <Col md={6}>
            {/* BUMN INDUSTRIAL SECTOR */}
            <div className="mb-3">
              <DivText
                label="BUMN Industrial Sector"
                content={dataDetail?.r11_industrial_sector || "-"}
              />
            </div>

            {/* CODE */}
            <div className="mb-3">
              <DivText label="Code" content={dataDetail?.r11_code || "-"} />
            </div>
          </Col>
          <Col md={6}>
            {/* FIELD OF STUDY */}
            <div className="mb-3">
              <DivText
                label="Field of Study"
                content={dataDetail?.r11_field_study || "-"}
              />
            </div>

            {/* SPECIFIC MAJOR */}
            <div className="mb-3">
              <DivText
                label="Specific Major"
                content={dataDetail?.r11_specific_major || "-"}
              />
            </div>
          </Col>
        </Row>

        {/* REASONS */}
        <div className="mb-3">
          <DivText
            label="In 150 words or less, describe the reason(s) why you chose this field of study."
            content={dataDetail?.r11_reason || "-"}
          />
        </div>

        <hr />
      </Form>
    </React.Fragment>
  )
}

export default TabPreviewFormFieldOfStudy
