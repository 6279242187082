/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputFile from "components/Input/InputFile"
import InputSingleSelect from "components/Input/InputSingleSelect"
import InputDateMask from "components/Input/InputDateMask"
import InputTextArea from "components/Input/InputTextArea"
import InputEmail from "components/Input/InputEmail"
import InputNumber from "components/Input/InputNumber"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { canSubmitRegister, scrollTop } from "helpers/utils_helper"

const TabFormReferenceSummary = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
  reset = false,
  onResetFalse = () => {},
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (reset) {
      setError("")
      onResetFalse()
    }
  }, [reset])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name_a: dataDetail?.r14_name_a || "",
      institution_a: dataDetail?.r14_institution_a || "",
      position_a: dataDetail?.r14_position_a || "",
      phone_a: dataDetail?.r14_phone_a || "",
      email_a: dataDetail?.r14_email_a || "",

      name_b: dataDetail?.r14_name_b || "",
      institution_b: dataDetail?.r14_institution_b || "",
      position_b: dataDetail?.r14_position_b || "",
      phone_b: dataDetail?.r14_phone_b || "",
      email_b: dataDetail?.r14_email_b || "",

      name_c: dataDetail?.r14_name_c || "",
      institution_c: dataDetail?.r14_institution_c || "",
      position_c: dataDetail?.r14_position_c || "",
      phone_c: dataDetail?.r14_phone_c || "",
      email_c: dataDetail?.r14_email_c || "",
    },

    onSubmit: values => {
      if (canSubmitRegister(dataDetail?.status)) {
        if (!loading) postBeasiswaRegister(values)
      } else {
        onNext()
      }
    },
  })

  const postBeasiswaRegister = async data => {
    try {
      setLoading(true)
      const response = await postAPIBeasiswaRegister(data, "14")
      setLoading(false)
      if (response.status === "SUCCESS") {
        onSuccess(response.message)
        onNext()
      } else {
        scrollTop()
        setError(response.message)
        
      }
    } catch (error) {
      setLoading(false)
      scrollTop()
      setError("Something went wrong, please try again")
     
    }
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <p className="card-title-desc">
          Please identify three people, who are not related to you, and can
          serve as personal or professional references and attest to your skills
          and experiences in leadership, technical knowledge, and problem
          solving, academics, and/or community service. This reference summary
          should stay attached to the application form. A model reference letter
          form to be completed and sealed by your selected referees is included
          at the end of this application.
        </p>

        {/* a. Name of Referee */}
        <div className="mb-3">
          <InputText
            label="Name of Referee"
            name="name_a"
            placeholder="Name of Referee"
            value={_formik.values.name_a || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <Row>
          <Col md={6}>
            {/* Institution */}
            <div className="mb-3">
              <InputText
                label="Institution"
                name="institution_a"
                placeholder="Institution"
                value={_formik.values.institution_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Telephone*/}
            <div className="mb-3">
              <InputText
                label="Telephone"
                name="phone_a"
                placeholder="telephone"
                value={_formik.values.phone_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Position */}
            <div className="mb-3">
              <InputText
                label="Position"
                name="position_a"
                placeholder="Position"
                value={_formik.values.position_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Email */}
            <div className="mb-3">
              <InputEmail
                label="Email"
                name="email_a"
                placeholder="Email"
                value={_formik.values.email_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <hr />

        {/* b. Name of Referee */}
        <div className="mb-3">
          <InputText
            label="Name of Referee"
            name="name_b"
            placeholder="Name of Referee"
            value={_formik.values.name_b || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <Row>
          <Col md={6}>
            {/* Institution */}
            <div className="mb-3">
              <InputText
                label="Institution"
                name="institution_b"
                placeholder="Institution"
                value={_formik.values.institution_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Telephone*/}
            <div className="mb-3">
              <InputText
                label="Telephone"
                name="phone_b"
                placeholder="telephone"
                value={_formik.values.phone_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Position */}
            <div className="mb-3">
              <InputText
                label="Position"
                name="position_b"
                placeholder="Position"
                value={_formik.values.position_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Email */}
            <div className="mb-3">
              <InputEmail
                label="Email"
                name="email_b"
                placeholder="Email"
                value={_formik.values.email_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <hr />

        {/* c. Name of Referee */}
        <div className="mb-3">
          <InputText
            label="Name of Referee"
            name="name_c"
            placeholder="Name of Referee"
            value={_formik.values.name_c || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <Row>
          <Col md={6}>
            {/* Institution */}
            <div className="mb-3">
              <InputText
                label="Institution"
                name="institution_c"
                placeholder="Institution"
                value={_formik.values.institution_c || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Telephone*/}
            <div className="mb-3">
              <InputText
                label="Telephone"
                name="phone_c"
                placeholder="telephone"
                value={_formik.values.phone_c || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Position */}
            <div className="mb-3">
              <InputText
                label="Position"
                name="position_c"
                placeholder="Position"
                value={_formik.values.position_c || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Email */}
            <div className="mb-3">
              <InputEmail
                label="Email"
                name="email_c"
                placeholder="Email"
                value={_formik.values.email_c || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <hr />

        <div className="d-flex justify-content-end">
          {onPrev && (
            <Link
              to="#"
              className={`btn btn-primary btn-block me-2 ${loading ? 'disabled' : ''}`}
              onClick={onPrev}
            >
              Previous
            </Link>
          )}

          <button className="btn btn-primary btn-block" type="submit">
            {loading ? <Spinner size="sm" /> : "Next"}
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TabFormReferenceSummary
