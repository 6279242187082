/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputFile from "components/Input/InputFile"
import InputSingleSelect from "components/Input/InputSingleSelect"
import InputDateMask from "components/Input/InputDateMask"
import InputTextArea from "components/Input/InputTextArea"
import InputEmail from "components/Input/InputEmail"
import InputNumber from "components/Input/InputNumber"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import {
  canSubmitRegister,
  scrollTop,
  statusBeasiswaRegister
} from "helpers/utils_helper"

const TabFormPersonalInformation = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
  reset = false,
  onResetFalse = () => {},
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (reset) {
      setError("")
      onResetFalse()
    }
  }, [reset])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      first_name: dataDetail?.r01_first_name || "",
      middle_name: dataDetail?.r01_middle_name || "",
      last_name: dataDetail?.r01_last_name || "",
      id_type: dataDetail?.r01_id_type || "",
      id_number: dataDetail?.r01_id_number || "",
      id_expiry:
        dataDetail !== null && dataDetail?.r01_id_expiry !== "0000-00-00"
          ? dataDetail?.r01_id_expiry
          : "",
      birth_date:
        dataDetail !== null && dataDetail?.r01_birth_date !== "0000-00-00"
          ? dataDetail?.r01_birth_date
          : "",
      birth_place: dataDetail?.r01_birth_place || "",
      gender: dataDetail?.r01_gender || "",
      nationality: dataDetail?.r01_nationality || "",
      address: dataDetail?.r01_address || "",
      city: dataDetail?.r01_city || "",
      province: dataDetail?.r01_province || "",
      postal_code: dataDetail?.r01_postal_code || "",
      home_phone: dataDetail?.r01_home_phone || "",
      mobile_phone: dataDetail?.r01_mobile_phone || "",
      email: dataDetail?.r01_email || "",
      marital_status: dataDetail?.r01_marital_status || "",
    },

    onSubmit: values => {
      values = {
        ...values,
        birth_date: values.birth_date,
        id_expiry: values.id_expiry,
      }

      
      if (canSubmitRegister(dataDetail?.status)) {
        if (!loading) postBeasiswaRegister(values)
      } else {
        onNext()
      }
    },
  })

  const postBeasiswaRegister = async data => {
    try {
      setLoading(true)
      const response = await postAPIBeasiswaRegister(data, "01")
      setLoading(false)
      if (response.status === "SUCCESS") {
        onSuccess(response.message)
        onNext()
      } else {
        scrollTop()
        setError(response.message)
      }
    } catch (error) {
      setLoading(false)
      scrollTop()
      setError("Something went wrong, please try again")
    }
  }

  const optionsNationalId = [
    { label: "KTP", value: "ktp" },
    { label: "Passport", value: "passport" },
  ]

  const optionsGender = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ]

  const optionsMaritalStatus = [
    { label: "Single", value: "single" },
    { label: "Married", value: "married" },
    { label: "Widow / Widower", value: "widow" },
  ]

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <p className="card-title-desc">
          Please provide your personal details exactly as they appear on
          official documents (National ID, Passport, Birth Certificate, etc.).
          Each field is mandatory.
        </p>

        {/* FIRST NAME */}
        <div className="mb-3">
          <InputText
            label="First Name"
            name="first_name"
            placeholder="First Name"
            value={_formik.values.first_name || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        {/* Middle NAME */}
        <div className="mb-3">
          <InputText
            label="Middle Name"
            name="middle_name"
            placeholder="Middle Name"
            value={_formik.values.middle_name || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        {/* LAST NAME */}
        <div className="mb-3">
          <InputText
            label="Last Name"
            name="last_name"
            placeholder="Last Name"
            infoMsg="*If you have only one name, please write down your name in the Last Name section."
            value={_formik.values.last_name || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>
        <hr />
        <Row>
          <Col md={6}>
            {/* RECENT PHOTO
            <div className="mb-3">
              <InputFile
                label="Recent Photo (JPG - Max 2MB)"
                name="recent_photo"
                onChange={event => {
                  _formik.setFieldValue(
                    "recent_photo",
                    event.currentTarget.files[0]
                  )
                }}
                onBlur={_formik.handleBlur}
              />
            </div> */}

            {/* Nationality */}
            <div className="mb-3">
              <InputText
                label="Nationality"
                name="nationality"
                placeholder="Nationality"
                value={_formik.values.nationality || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* NATIONAL ID DETAILS */}
            <div className="mb-3">
              <InputSingleSelect
                label="National ID Details"
                name="id_type"
                options={optionsNationalId}
                value={
                  optionsNationalId
                    ? optionsNationalId.find(
                        option => option.value === _formik.values.id_type
                      )
                    : ""
                }
                onChange={option =>
                  _formik.setFieldValue("id_type", option.value)
                }
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* NUMBER NATIONAL ID */}
            <div className="mb-3">
              <InputText
                label="National ID Number"
                name="id_number"
                placeholder="Number National ID"
                value={_formik.values.id_number || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* EXPIRY DATE */}
            <div className="mb-3">
              <InputDateMask
                label="National ID Expiry Date (Passport)"
                name="id_expiry"
                mask="99/99/9999"
                placeholder="Expiry Date (DD/MM/YYYY)"
                value={_formik.values.id_expiry || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* DATE OF BIRTH */}
            <div className="mb-3">
              <InputDateMask
                label="Date of Birth"
                name="birth_date"
                mask="99/99/9999"
                placeholder="Date of Birth (DD/MM/YYYY)"
                value={_formik.values.birth_date || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Place of Birth */}
            <div className="mb-3">
              <InputText
                label="Place of Birth"
                name="birth_place"
                placeholder="Place of Birth"
                value={_formik.values.birth_place || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* GENDER */}
            <div className="mb-3">
              <InputSingleSelect
                label="Gender"
                name="gender"
                options={optionsGender}
                value={
                  optionsGender
                    ? optionsGender.find(
                        option => option.value === _formik.values.gender
                      )
                    : ""
                }
                onChange={option =>
                  _formik.setFieldValue("gender", option.value)
                }
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Nationality
            <div className="mb-3">
              <InputText
                label="Nationality"
                name="nationality"
                placeholder="Nationality"
                value={_formik.values.nationality || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div> */}
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md={6}>
            {/* City */}
            <div className="mb-3">
              <InputText
                label="City"
                name="city"
                placeholder="City"
                value={_formik.values.city || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Province */}
            <div className="mb-3">
              <InputText
                label="Province"
                name="province"
                placeholder="Province"
                value={_formik.values.province || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* POSTAL CODE */}
            <div className="mb-3">
              <InputText
                label="Postal Code"
                name="postal_code"
                placeholder="Postal Code"
                value={_formik.values.postal_code || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* CURRENT RESIDENTIAL ADDRESS */}
            <div className="mb-3">
              <InputTextArea
                label="Current Residential Address (if your current address is different from the address on your ID card, please write it below)"
                name="address"
                placeholder="Current Residential Address"
                value={_formik.values.address || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md={6}>
            {/* HOME TELEPHONE */}
            <div className="mb-3">
              <InputText
                label="Home Telephone"
                name="home_phone"
                placeholder="Home Telephone"
                infoMsg="*Please include the code area"
                value={_formik.values.home_phone || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Email */}
            <div className="mb-3">
              <InputEmail
                label="Email"
                name="email"
                placeholder="Email"
                value={_formik.values.email || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* MOBILE TELEPHONE */}
            <div className="mb-3">
              <InputText
                label="Mobile Number"
                name="mobile_phone"
                placeholder="Mobile Number"
                value={_formik.values.mobile_phone || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Marital Status */}
            <div className="mb-3">
              <InputSingleSelect
                label="Marital Status"
                name="marital_status"
                options={optionsMaritalStatus}
                value={
                  optionsMaritalStatus
                    ? optionsMaritalStatus.find(
                        option => option.value === _formik.values.marital_status
                      )
                    : ""
                }
                onChange={option =>
                  _formik.setFieldValue("marital_status", option.value)
                }
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <hr />

        <div className="d-flex justify-content-end">
          {onPrev && (
            <Link
              to="#"
              className={`btn btn-primary btn-block me-2 ${
                loading ? "disabled" : ""
              }`}
              onClick={onPrev}
            >
              Previous
            </Link>
          )}

          <button className="btn btn-primary btn-block" type="submit">
            {loading ? <Spinner size="sm" /> : "Next"}
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TabFormPersonalInformation
