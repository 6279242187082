/* eslint-disable react/prop-types */
import React, { useState } from "react"
import { Input, FormFeedback, Label } from "reactstrap"
import Select from "react-select";

const InputSingleSelect = ({
  label,
  name,
  value,
  invalid,
  errorMsg,
  infoMsg,
  options,
  onChange = () => {},
  onBlur = () => {},
}) => {
  return (
    <React.Fragment>
      <Label className="form-label">{label}</Label>
      <Select
        name={name}
        value={value}
        onChange={onChange}
        options={options}
        classNamePrefix="select2-selection"
        onBlur={onBlur}
      />
      {infoMsg && <small className="form-text text-muted">{infoMsg}</small>}
      {invalid ? <FormFeedback type="invalid">{errorMsg}</FormFeedback> : null}
    </React.Fragment>
  )
}

export default InputSingleSelect
