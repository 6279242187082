/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { Alert, Form, FormFeedback, Spinner } from "reactstrap"
import InputTextArea from "components/Input/InputTextArea"
import { Link, useHistory } from "react-router-dom"
import {
  getAPIBeasiswaRegister,
  postAPIBeasiswaRegister,
} from "helpers/BackendHelper/beasiswa_register_backend"
import { canSubmitRegister, scrollTop } from "helpers/utils_helper"
import DivText from "components/Input/DivText"
import TabPreviewFormPersonalInformation from "./tab-form-personal-information"
import TabPreviewFormPhysicalDisabilities from "./tab-form-physical-disabilities"
import TabPreviewFormEmergencyContactInformation from "./tab-form-emergency-contact-information"
import TabPreviewFormEnglishLanguageProficiency from "./tab-form-english-language-proficiency"
import TabPreviewFormEducationalBackground from "./tab-form-educational-background"
import TabPreviewFormScholarshipHistory from "./tab-form-scholarship-history"
import TabPreviewFormEmploymentHistory from "./tab-form-employment-history"
import TabPreviewFormCommunityServiceActivities from "./tab-form-community-service-activities"
import TabPreviewFormPublications from "./tab-form-publications"
import TabPreviewFormAwards from "./tab-form-awards"
import TabPreviewFormFieldOfStudy from "./tab-form-field-of-study"
import TabPreviewFormProposedUniversity from "./tab-form-proposed_university"
import TabPreviewFormEssays from "./tab-form-essays"
import TabPreviewFormReferenceSummary from "./tab-form-reference-summary"
import TabPreviewFormRequiredDocuments from "./tab-form-required-documents"
import InputCheckbox from "components/Input/InputCheckbox"

const TabPreview = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  reset = false,
  onResetFalse = () => {},
}) => {
  const [dataDetail, setDataDetail] = useState(null)

  const history = useHistory()
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState(false)

  const [mustCheck, setMustCheck] = useState("")

  useEffect(() => {
    if (reset) {
      setError("")
      onResetFalse()
    }
  }, [reset])

  useEffect(() => {
    getBeasiswaRegister()
  }, [])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      is_submit: "1",
      is_correct: false,
    },

    validationSchema: Yup.object().shape({
      is_correct: Yup.bool() // use bool instead of boolean
        .oneOf([true], "Please complete the statement of data truth"),
    }),

    validate: value => {
      if (value.is_correct) {
        setMustCheck("")
      } else {
        setMustCheck("Please complete the statement of data truth")
      }
    },

    onSubmit: values => {
      let data
      if (values.is_correct) {
        data = {
          ...values,
          is_correct: 1,
        }
      } else {
        data = {
          ...values,
          is_correct: 0,
        }
      }

      if (canSubmitRegister(dataDetail?.status)) {
        if (!loading) postBeasiswaRegister(data)
      }
    },
  })

  const getBeasiswaRegister = async data => {
    try {
      const response = await getAPIBeasiswaRegister()
      if (response.status === "SUCCESS") {
        setDataDetail(response.data[0])
      } else {
      }
    } catch (error) {}
  }

  const postBeasiswaRegister = async data => {
    try {
      setLoading(true)
      const response = await postAPIBeasiswaRegister(data, "16")
      setLoading(false)
      debugger
      if (response.status === "SUCCESS") {
        onSuccess(response.message)
        if (response.do === "redirect") {
          setTimeout(() => {
            history.push("/status-pengajuan-beasiswa")
          }, 1000)
        }
      } else {
        scrollTop()
        setError(response.message)
      }
    } catch (error) {
      setLoading(false)
      scrollTop()
      setError("Something went wrong, please try again")
    }
  }

  return (
    <React.Fragment>
      {error && error ? <Alert color="danger">Error: {error}</Alert> : null}
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        <TabPreviewFormPersonalInformation dataDetail={dataDetail} />
        <TabPreviewFormPhysicalDisabilities dataDetail={dataDetail} />
        <TabPreviewFormEmergencyContactInformation dataDetail={dataDetail} />
        <TabPreviewFormEnglishLanguageProficiency dataDetail={dataDetail} />
        <TabPreviewFormEducationalBackground dataDetail={dataDetail} />
        <TabPreviewFormScholarshipHistory dataDetail={dataDetail} />
        <TabPreviewFormEmploymentHistory dataDetail={dataDetail} />
        <TabPreviewFormCommunityServiceActivities dataDetail={dataDetail} />
        <TabPreviewFormPublications dataDetail={dataDetail} />
        <TabPreviewFormAwards dataDetail={dataDetail} />
        <TabPreviewFormFieldOfStudy dataDetail={dataDetail} />
        <TabPreviewFormProposedUniversity dataDetail={dataDetail} />
        <TabPreviewFormEssays dataDetail={dataDetail} />
        <TabPreviewFormReferenceSummary dataDetail={dataDetail} />
        <TabPreviewFormRequiredDocuments dataDetail={dataDetail} />

        {canSubmitRegister(dataDetail?.status) && (
          <div className="mb-3">
            <InputCheckbox
              label={""}
              options={[
                {
                  label:
                    "I hereby declare that all the information and documents attached are correct",
                  value: true,
                },
              ]}
              name="is_correct"
              value={_formik.values.is_correct || ""}
              onChange={_formik.handleChange}
              errorMsg={mustCheck}
            />
          </div>
        )}
        

        <div className="d-flex justify-content-end">
          {onPrev && (
            <Link
              to="#"
              className={`btn btn-primary btn-block me-2 ${
                loading ? "disabled" : ""
              }`}
              onClick={onPrev}
            >
              Previous
            </Link>
          )}

          {canSubmitRegister(dataDetail?.status) && (
            <button className="btn btn-primary btn-block" type="submit">
              {loading ? <Spinner size="sm" /> : "Submit"}
            </button>
          )}
          
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TabPreview
