import { del, get, post, put } from "../api_helper"
import * as url from "../url_helper"

const postAPIBeasiswaRegister = (data, page) => {
  var formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key]);
  }

  return post(url.POST_BEASISWA_REGISTER + page, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

const getAPIBeasiswaRegister = () => {
  return get(url.GET_BEASISWA_REGISTER, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

const getAPIStatusPengajuanBeasiswa = () => {
  return get(url.GET_BEASISWA_STATUS, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}


export { postAPIBeasiswaRegister, getAPIBeasiswaRegister, getAPIStatusPengajuanBeasiswa }
