/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputDateMask from "components/Input/InputDateMask"
import InputTextArea from "components/Input/InputTextArea"
import InputNumber from "components/Input/InputNumber"
import InputRadio from "components/Input/InputRadio"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { canSubmitRegister, scrollTop } from "helpers/utils_helper"

const TabFormScholarshipHistory = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
  reset = false,
  onResetFalse = () => {},
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (reset) {
      setError("")
      onResetFalse()
    }
  }, [reset])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      receive_funding: dataDetail?.r06_receive_funding || "no",
      scholarship_title: dataDetail?.r06_scholarship_title || "",
      scholarship_provider: dataDetail?.r06_scholarship_provider || "",
      award_duration: dataDetail?.r06_award_duration || "",
      contact_person: dataDetail?.r06_contact_person || "",
      description: dataDetail?.r06_description || "",
    },

    onSubmit: values => {
      if (canSubmitRegister(dataDetail?.status)) {
        if (!loading) postBeasiswaRegister(values)
      } else {
        onNext()
      }
    },
  })

  const postBeasiswaRegister = async data => {
    try {
      setLoading(true)
      const response = await postAPIBeasiswaRegister(data, "06")
      setLoading(false)
      if (response.status === "SUCCESS") {
        onSuccess(response.message)
        onNext()
      } else {
        scrollTop()
        setError(response.message)
        
      }
    } catch (error) {
      setLoading(false)
      scrollTop()
      setError("Something went wrong, please try again")
     
    }
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <p className="card-title-desc">
          List any educational scholarships or fellowships that you received in
          the past, starting at the university level. Include additional
          examples or details on an additional page as necessary.
        </p>

        <div className="mb-3">
          <InputRadio
          name="receive_funding"
            label="Have you ever received any scholarship or other educational funding support from any institution?"
            infoMsg="*If no, proceed to the next section."
            value={_formik.values.receive_funding || ""}
            onChange={_formik.handleChange}
            options={[
              {
                label: "Yes",
                value: "yes",
              },
              {
                label: "No",
                value: "no",
              },
            ]}
          />
        </div>

        <Row>
          <Col md={6}>
            {/* SCHOLARSHIP TITLE */}
            <div className="mb-3">
              <InputText
                label="Scholarship Title"
                name="scholarship_title"
                placeholder="Scholarship Title"
                value={_formik.values.scholarship_title || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* SCHOLARSHIP Provider */}
            <div className="mb-3">
              <InputText
                label="Scholarship Provider"
                name="scholarship_provider"
                placeholder="Scholarship Providee"
                value={_formik.values.scholarship_provider || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* AWARD DATE AND DURATION*/}
            <div className="mb-3">
              <div className="mb-3">
                <InputText
                  label="Award Date and Duration"
                  name="award_duration"
                  placeholder="Award Date (MM/YY) and duration"
                  value={_formik.values.award_duration || ""}
                  onChange={_formik.handleChange}
                  onBlur={_formik.handleBlur}
                />
              </div>
            </div>

            {/* CONTACT PERSON */}
            <div className="mb-3">
              <InputText
                label="Contact Person"
                name="contact_person"
                placeholder="Contact Person"
                value={_formik.values.contact_person || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        {/* DESCRIBE THE SCHOLARSHIP */}
        <div className="mb-3">
          <InputTextArea
            label="Describe the scholarship and comment on the type/purpose; full or partial funding; what level of education; degree or non-degree studies"
            name="description"
            placeholder=""
            value={_formik.values.description || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <hr />

        <div className="d-flex justify-content-end">
          {onPrev && (
            <Link
              to="#"
              className={`btn btn-primary btn-block me-2 ${loading ? 'disabled' : ''}`}
              onClick={onPrev}
            >
              Previous
            </Link>
          )}

          <button className="btn btn-primary btn-block" type="submit">
            {loading ? <Spinner size="sm" /> : "Next"}
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TabFormScholarshipHistory
