/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputNumber from "components/Input/InputNumber"
import InputDateRangeMask from "components/Input/InputDateRangeMask"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import {
  canSubmitRegister,
  scrollTop,
} from "helpers/utils_helper"
import InputDateMask from "components/Input/InputDateMask"

const TabFormEducationalBackground = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
  reset = false,
  onResetFalse = () => {},
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (reset) {
      setError("")
      onResetFalse()
    }
  }, [reset])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      university_a: dataDetail?.r05_university_a || "",
      degree_a: dataDetail?.r05_degree_a || "",
      city_a: dataDetail?.r05_city_a || "",
      country_a: dataDetail?.r05_country_a || "",
      date_start_a:
        dataDetail !== null && dataDetail?.r05_date_end_a !== "0000-00-00"
          ? dataDetail?.r05_date_start_a
          : "",
      date_end_a:
        dataDetail !== null && dataDetail?.r05_date_end_a !== "0000-00-00"
          ? dataDetail?.r05_date_end_a
          : "",
      gpa_a: dataDetail?.r05_gpa_a || "",
      faculty_a: dataDetail?.r05_faculty_a || "",
      major_a: dataDetail?.r05_major_a || "",

      university_b: dataDetail?.r05_university_b || "",
      degree_b: dataDetail?.r05_degree_b || "",
      city_b: dataDetail?.r05_city_b || "",
      country_b: dataDetail?.r05_country_b || "",
      date_start_b:
        dataDetail !== null && dataDetail?.r05_date_start_b !== "0000-00-00"
          ? dataDetail?.r05_date_start_b
          : "",
      date_end_b:
        dataDetail !== null && dataDetail?.r05_date_end_b !== "0000-00-00"
          ? dataDetail?.r05_date_end_b
          : "",
      gpa_b: dataDetail?.r05_gpa_b || "",
      faculty_b: dataDetail?.r05_faculty_b || "",
      major_b: dataDetail?.r05_major_b || "",

      university_c: dataDetail?.r05_university_c || "",
      degree_c: dataDetail?.r05_degree_c || "",
      city_c: dataDetail?.r05_city_c || "",
      country_c: dataDetail?.r05_country_c || "",
      date_start_c:
        dataDetail !== null && dataDetail?.r05_date_start_c !== "0000-00-00"
          ? dataDetail?.r05_date_start_c
          : "",
      date_end_c:
        dataDetail !== null && dataDetail?.r05_date_end_a !== "0000-00-00"
          ? dataDetail?.r05_date_end_c
          : "",
      gpa_c: dataDetail?.r05_gpa_c || "",
      faculty_c: dataDetail?.r05_faculty_c || "",
      major_c: dataDetail?.r05_major_c || "",
    },

    onSubmit: values => {
      values = {
        ...values,
        date_start_a: values.date_start_a,
        date_end_a: values.date_end_a,

        date_start_b: values.date_start_b,
        date_end_b: values.date_end_b,

        date_start_c: values.date_start_c,
        date_end_c: values.date_end_c,
      }

      if (canSubmitRegister(dataDetail?.status)) {
        if (!loading) postBeasiswaRegister(values)
      } else {
        onNext()
      }
    },
  })

  const postBeasiswaRegister = async data => {
    try {
      setLoading(true)
      const response = await postAPIBeasiswaRegister(data, "05")
      setLoading(false)
      if (response.status === "SUCCESS") {
        onSuccess(response.message)
        onNext()
      } else {
        scrollTop()
        setError(response.message)
      }
    } catch (error) {
      setLoading(false)
      scrollTop()
      setError("Something went wrong, please try again")
    }
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <p className="card-title-desc">
          List universities and colleges you attended and degree(s) attained
          starting with the most recent.
        </p>

        <Row>
          <Col md={6}>
            {/* A. NAME OF UNIVERSITY */}
            <div className="mb-3">
              <InputText
                label="Name of University"
                name="university_a"
                placeholder="Name of University"
                value={_formik.values.university_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* LOCATION, CITY */}
            <div className="mb-3">
              <InputText
                label="Location, City"
                name="city_a"
                placeholder="Location, City"
                value={_formik.values.city_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* DATE ATTENDED START */}
            <div className="mb-3">
              <InputDateMask
                label="Date Attended Start"
                name="date_start_a"
                mask="99/99/9999"
                placeholder="Date Attended Start (DD/MM/YYYY)"
                value={_formik.values.date_start_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* DATE ATTENDED END */}
            <div className="mb-3">
              <InputDateMask
                label="Date Attended End"
                name="date_end_a"
                mask="99/99/9999"
                placeholder="Date Attended end (DD/MM/YYYY)"
                value={_formik.values.date_end_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* FACULTY */}
            <div className="mb-3">
              <InputText
                label="Faculty"
                name="faculty_a"
                placeholder="Faculty"
                value={_formik.values.faculty_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* DEGREE OBTAINED */}
            <div className="mb-3">
              <InputText
                label="Degree Obtained"
                name="degree_a"
                placeholder="Degree Obtained"
                value={_formik.values.degree_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* COUNTRY */}
            <div className="mb-3">
              <InputText
                label="Country"
                name="country_a"
                placeholder="Country"
                value={_formik.values.country_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* CURRENT OR FINAL GPA */}
            <div className="mb-3">
              <InputNumber
                label="Current or Final GPA"
                name="gpa_a"
                placeholder="Current or Final GPA"
                value={_formik.values.gpa_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* MAJOR */}
            <div className="mb-3">
              <InputText
                label="Major"
                name="major_a"
                placeholder="Major"
                value={_formik.values.major_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md={6}>
            {/* B. NAME OF UNIVERSITY */}
            <div className="mb-3">
              <InputText
                label="Name of University"
                name="university_b"
                placeholder="Name of University"
                value={_formik.values.university_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* LOCATION, CITY */}
            <div className="mb-3">
              <InputText
                label="Location, City"
                name="city_b"
                placeholder="Location, City"
                value={_formik.values.city_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* DATE ATTENDED START */}
            <div className="mb-3">
              <InputDateMask
                label="Date Attended Start"
                name="date_start_b"
                mask="99/99/9999"
                placeholder="Date Attended Start (DD/MM/YYYY)"
                value={_formik.values.date_start_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* DATE ATTENDED END */}
            <div className="mb-3">
              <InputDateMask
                label="Date Attended End"
                name="date_end_b"
                mask="99/99/9999"
                placeholder="Date Attended end (DD/MM/YYYY)"
                value={_formik.values.date_end_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* FACULTY */}
            <div className="mb-3">
              <InputText
                label="Faculty"
                name="faculty_b"
                placeholder="Faculty"
                value={_formik.values.faculty_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* DEGREE OBTAINED */}
            <div className="mb-3">
              <InputText
                label="Degree Obtained"
                name="degree_b"
                placeholder="Degree Obtained"
                value={_formik.values.degree_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* COUNTRY */}
            <div className="mb-3">
              <InputText
                label="Country"
                name="country_b"
                placeholder="Country"
                value={_formik.values.country_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* CURRENT OR FINAL GPA */}
            <div className="mb-3">
              <InputNumber
                label="Current or Final GPA"
                name="gpa_b"
                placeholder="Current or Final GPA"
                value={_formik.values.gpa_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* MAJOR */}
            <div className="mb-3">
              <InputText
                label="Major"
                name="major_b"
                placeholder="Major"
                value={_formik.values.major_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md={6}>
            {/* C. NAME OF UNIVERSITY */}
            <div className="mb-3">
              <InputText
                label="Name of University"
                name="university_c"
                placeholder="Name of University"
                value={_formik.values.university_c || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* LOCATION, CITY */}
            <div className="mb-3">
              <InputText
                label="Location, City"
                name="city_c"
                placeholder="Location, City"
                value={_formik.values.city_c || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* DATE ATTENDED START */}
            <div className="mb-3">
              <InputDateMask
                label="Date Attended Start"
                name="date_start_c"
                mask="99/99/9999"
                placeholder="Date Attended Start (DD/MM/YYYY)"
                value={_formik.values.date_start_c || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* DATE ATTENDED END */}
            <div className="mb-3">
              <InputDateMask
                label="Date Attended End"
                name="date_end_c"
                mask="99/99/9999"
                placeholder="Date Attended end (DD/MM/YYYY)"
                value={_formik.values.date_end_c || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
            {/* FACULTY */}
            <div className="mb-3">
              <InputText
                label="Faculty"
                name="faculty_c"
                placeholder="Faculty"
                value={_formik.values.faculty_c || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* DEGREE OBTAINED */}
            <div className="mb-3">
              <InputText
                label="Degree Obtained"
                name="degree_c"
                placeholder="Degree Obtained"
                value={_formik.values.degree_c || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* COUNTRY */}
            <div className="mb-3">
              <InputText
                label="Country"
                name="country_c"
                placeholder="Country"
                value={_formik.values.country_c || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* CURRENT OR FINAL GPA */}
            <div className="mb-3">
              <InputNumber
                label="Current or Final GPA"
                name="gpa_c"
                placeholder="Current or Final GPA"
                value={_formik.values.gpa_c || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* MAJOR */}
            <div className="mb-3">
              <InputText
                label="Major"
                name="major_c"
                placeholder="Major"
                value={_formik.values.major_c || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <hr />

        <div className="d-flex justify-content-end">
          {onPrev && (
            <Link
              to="#"
              className={`btn btn-primary btn-block me-2 ${
                loading ? "disabled" : ""
              }`}
              onClick={onPrev}
            >
              Previous
            </Link>
          )}

          <button className="btn btn-primary btn-block" type="submit">
            {loading ? <Spinner size="sm" /> : "Next"}
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TabFormEducationalBackground
