export const statusBeasiswaRegister = {
  EMPTY: "empty",
  PASS: "pass",
  SUBMIT: "submit",
  DRAFT: "draft",
  FAIL: "fail",
}

export const canSubmitRegister = status => {
  if (
    status === statusBeasiswaRegister.DRAFT ||
    status === statusBeasiswaRegister.EMPTY
  ) {
    return true
  } else {
    return false
  }
}

export const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  })
}

export const scrollIntoView = id => {
  const yOffset = -10
  const element = document.getElementById(id)
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

  window.scrollTo({ top: y, behavior: "smooth" })
}

export const stringToDate = (strDate) => {
  return strDate;
}

export const swapFormatedDate = (
  strDate,
  formatCurrent = "yyyy-mm-dd",//"dd/mm/yyyy",
  formatNew = "dd/mm/yyyy"//"yyyy-mm-dd"
) => {

  //date format = mm/dd/yyyy
  try {
    let date
    let ret
    let monthNum
    let dateNum
    switch (formatCurrent) {
      case "dd/mm/yyyy":
        strDate = `${strDate.substring(3, 5)}/${strDate.substring(
          0,
          2
        )}/${strDate.substring(6, 10)}/`
        date = new Date(strDate)
        break
      case "yyyy-mm-dd":
        strDate = `${strDate.substring(5, 7)}/${strDate.substring(
          8,
          10
        )}/${strDate.substring(0, 4)}/`
        date = new Date(strDate)
        break
      default:
        break
    }

    switch (formatNew) {
      case "yyyy-mm-dd":
        monthNum =
          date.getMonth().toString().length > 1
            ? date.getMonth() + 1
            : `0${date.getMonth() + 1}`
        dateNum =
          date.getDate().toString().length > 1
            ? date.getDate()
            : `0${date.getDate()}`

        ret = `${date.getFullYear()}-${monthNum}-${dateNum}`
        break

      case "dd/mm/yyyy":
        monthNum =
          date.getMonth().toString().length > 1
            ? date.getMonth() + 1
            : `0${date.getMonth() + 1}`
        dateNum =
          date.getDate().toString().length > 1
            ? date.getDate()
            : `0${date.getDate()}`

        ret = `${dateNum}/${monthNum}/${date.getFullYear()}`
        break

      default:
        break
    }

    return ret
  } catch (error) {
    return ""
  }
}
