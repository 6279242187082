import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import parse from "html-react-parser"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Alert,
  CardImg,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getAPIStatusPengajuanBeasiswa } from "helpers/BackendHelper/beasiswa_register_backend"
import imgBanner from "../../../assets/images/bg-login.jpeg";

const StatusPengajuanBeasiswa = () => {
  const [dataStatus, setDataStatus] = useState(null)

  useEffect(() => {
    getStatusPengajuanBeasiswa()
  }, [])

  const getStatusPengajuanBeasiswa = async data => {
    try {
      const response = await getAPIStatusPengajuanBeasiswa()
      if (response.status === "SUCCESS") {
        setDataStatus(response)
        setDataDetail(response.data[0])
      } else {
      }
    } catch (error) {}
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="page-content">
        <MetaTags>
          <title>Application Status | Beasiswa BUMN</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs
            title="Registration"
            breadcrumbItem="Application Status"
            titleMenu="Application Status"
          />

          <React.Fragment>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                  <CardImg className="img-fluid banner-home mb-4" src={imgBanner} alt="banner" />


                    {/* MESSAGES */}
                    {dataStatus?.message !== "" && (
                      <React.Fragment>
                        <label className="h6">Status</label>
                        <p className="card-title-desc mb-2">
                          {dataStatus?.message}
                        </p>
                      </React.Fragment>
                    )}

                    {/* FORM LINK */}
                    {dataStatus?.form_link === "show" && (
                      <div>
                        <p>
                          Klik:{" "}
                          <Link
                            to="/form-pengajuan-beasiswa"
                            Form
                            Pengajuan
                            Beasiswa
                          >
                            <strong>Submission Form</strong>
                          </Link>
                        </p>
                      </div>
                    )}

                    {/* SYARAT DAN KETENTUAN */}
                    {dataStatus?.syarat_ketentuan !== "" && (
                      <div className="mt-5">
                        {parse(dataStatus?.syarat_ketentuan || "")}
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default StatusPengajuanBeasiswa
