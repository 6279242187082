/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Input, FormFeedback, Label } from "reactstrap"

const InputCheckbox = ({
  name,
  label,
  infoMsg,
  options,
  value,
  invalid,
  errorMsg,
  onChange = () => {},
}) => {

  return (
    <React.Fragment>
      <Label className="form-label">{label}</Label>
      <div className="d-flex flex-wrap">
        {options.map((option, index) => {
          return (
            <div key={index} className="form-check me-3">
              <input
                className="form-check-input me-2"
                type="checkbox"
                name={name}
                onChange={onChange}
                defaultChecked={value === option.value}
                value={option.value}
              />
              <label className="form-check-label" htmlFor="exampleRadios2">
                {option.label}
              </label>
            </div>
          )
        })}
      </div>
      <div style={{color: "#f46a6a"}}>{errorMsg}</div>
      
    </React.Fragment>
  )
}

export default InputCheckbox
