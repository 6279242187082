/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputFile from "components/Input/InputFile"
import InputSingleSelect from "components/Input/InputSingleSelect"
import InputDateMask from "components/Input/InputDateMask"
import InputTextArea from "components/Input/InputTextArea"
import InputEmail from "components/Input/InputEmail"
import InputNumber from "components/Input/InputNumber"
import InputCheckbox from "components/Input/InputCheckbox"
import InputRadio from "components/Input/InputRadio"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { canSubmitRegister, scrollTop } from "helpers/utils_helper"

const TabFormProposedUniversity = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
  reset = false,
  onResetFalse = () => {},
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (reset) {
      setError("")
      onResetFalse()
    }
  }, [reset])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      university: dataDetail?.r12_university || "",
      faculty: dataDetail?.r12_faculty || "",
      address: dataDetail?.r12_address || "",
      request_admission: dataDetail?.r12_request_admission || "no",
      receive_admission: dataDetail?.r12_receive_admission || "no",
      receive_funding: dataDetail?.r12_receive_funding || "no",
      support_type: dataDetail?.r12_support_type || "",
      contact_person: dataDetail?.r12_contact_person || "",
      email: dataDetail?.r12_email || "",
      reason: dataDetail?.r12_reason || "",
    },

    onSubmit: values => {
      if (canSubmitRegister(dataDetail?.status)) {
        if (!loading) postBeasiswaRegister(values)
      } else {
        onNext()
      }
    },
  })

  const postBeasiswaRegister = async data => {
    try {
      setLoading(true)
      const response = await postAPIBeasiswaRegister(data, "12")
      setLoading(false)
      if (response.status === "SUCCESS") {
        onSuccess(response.message)
        onNext()
      } else {
        scrollTop()
        setError(response.message)
        
      }
    } catch (error) {
      setLoading(false)
      scrollTop()
      setError("Something went wrong, please try again")
     
    }
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <p className="card-title-desc">
          {`Yayasan BUMN works to match scholarship recipients with appropriate Master's Degree programs at foreign country universities. However, if you have already identified a university where you would like to study, please provide details. Include additional examples or details on an additional page as necessary.`}
        </p>

        <Row>
          <Col md={6}>
            {/* Name of University */}
            <div className="mb-3">
              <InputText
                label="Name of University"
                name="university"
                placeholder="Name of University"
                value={_formik.values.university || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Faculty / Departement */}
            <div className="mb-3">
              <InputText
                label="Faculty / Departement"
                name="faculty"
                placeholder="Faculty / Departement"
                value={_formik.values.faculty || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Complete Address */}
            <div className="mb-3">
              <InputTextArea
                label="Complete Address"
                name="address"
                placeholder="Complete Address"
                value={_formik.values.address || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
                rows={5}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className="mb-3">
              <InputRadio
                name="request_admission"
                label="Have you requested admission to this university?"
                value={_formik.values.request_admission || ""}
                onChange={_formik.handleChange}
                options={[
                  {
                    label: "Yes",
                    value: "yes",
                  },
                  {
                    label: "No",
                    value: "no",
                  },
                ]}
              />
            </div>
            <div className="mb-2">
              <InputRadio
                name="receive_funding"
                label="Are you receiving any funding support?"
                value={_formik.values.receive_funding || ""}
                onChange={_formik.handleChange}
                infoMsg="*If yes, please describe the type of support."
                options={[
                  {
                    label: "Yes",
                    value: "yes",
                  },
                  {
                    label: "No",
                    value: "no",
                  },
                ]}
              />
            </div>
            <div className="mb-3">
              <InputText
                name="support_type"
                placeholder="Type of Support"
                value={_formik.values.support_type || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <InputRadio
                name="receive_admission"
                label="Have you received an admission from the university?"
                infoMsg="*If yes, attach the Letter of Admission"
                value={_formik.values.receive_admission || ""}
                onChange={_formik.handleChange}
                options={[
                  {
                    label: "Yes",
                    value: "yes",
                  },
                  {
                    label: "No",
                    value: "no",
                  },
                ]}
              />
            </div>

            {/* LETTER
            <div className="mb-3">
              <InputFile
                name="a"
                onChange={event => {
                  _formik.setFieldValue("a", event.currentTarget.files[0])
                }}
                onBlur={_formik.handleBlur}
              />
            </div> */}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {/* Contact Person */}
            <div className="mb-3">
              <InputText
                label="Contact Person"
                name="contact_person"
                placeholder="Contact Person"
                value={_formik.values.contact_person || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Email */}
            <div className="mb-3">
              <InputEmail
                label="Email"
                name="email"
                placeholder="Email"
                value={_formik.values.email || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <div className="mb-3">
          <InputTextArea
            label="In 150 words or less, please describe why you choose this university."
            name="reason"
            placeholder=""
            value={_formik.values.reason || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
            rows={5}
          />
        </div>

        <hr />

        <div className="d-flex justify-content-end">
          {onPrev && (
            <Link
              to="#"
              className={`btn btn-primary btn-block me-2 ${loading ? 'disabled' : ''}`}
              onClick={onPrev}
            >
              Previous
            </Link>
          )}

          <button className="btn btn-primary btn-block" type="submit">
            {loading ? <Spinner size="sm" /> : "Next"}
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TabFormProposedUniversity
