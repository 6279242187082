/* eslint-disable react/prop-types */

import React, { useState } from "react"
import {
  Alert,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  Row,
  Spinner,
  Table,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"

import SweetAlert from "react-bootstrap-sweetalert"

const TabPreviewFormRequiredDocuments = ({ dataDetail = null }) => {
  const [info, setInfo] = useState(false)

  const previewFile = url => {
    if (url && url !== "") {
      window.open(url, "_target")
    } else {
      setInfo(true)
    }
  }

  return (
    <React.Fragment>
      <Form>
        <h4 className="mb-3 font-size-18">{"REQUIRED DOCUMENTS"}</h4>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="mb-1">Mandatory Documents</CardTitle>
                <div className="table-responsive">
                  <Table className="table table-bordered mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>
                          <div>Completed and signed application form</div>
                          <span className="font-size-11">
                            *Application form can be downloaded{" "}
                            <Link
                              to="https://beasiswabumn.jagoan.info/media/BUMN_Scholarship_Application_Form.pdf"
                              target={"_blank"}
                            >
                              <strong>here</strong>
                            </Link>
                          </span>
                        </td>

                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_application_form_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Certified Undergraduate Certificate</td>

                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(
                                dataDetail?.r15_undergraduate_certificate_url
                              )
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Certified Undergraduate Academic Transcript</td>

                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(
                                dataDetail?.r15_academic_transcript_url
                              )
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>Recent photographs (4 X 6, B/W or color)</td>

                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td>
                          Copy of valid national ID card (KTP or Passport)
                        </td>

                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_id_card_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td>
                          Valid TOEFL ITP or TOEFL iBT or IELTS or other
                          language proficiency score (test date only from
                          September 2020 and afterwards)
                        </td>

                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_toefl_score_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">7</th>
                        <td>Original BUMN Statement Letters</td>

                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_bumn_letter_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">8</th>
                        <td>Original Reference Letters</td>

                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_reference_letter_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="mb-1">Supplementary Documents</CardTitle>

                <div className="table-responsive">
                  <Table className="table table-bordered mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Copy Valid Letter of Acceptance</td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_acceptance_letter_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Copy of certificates and awards</td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_award_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>
                          Copy of article, journal, research or cover page of
                          your publication, etc.
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_publication_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>Recent Curriculum Vitae (in English)</td>

                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_cv_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td>
                          <div>Family Identity Card (Kartu Keluarga)</div>
                          <span className="font-size-11">
                            {`*additional documents for the category "Special Requirements for Public with Financial Disabilities"`}
                          </span>
                        </td>

                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_kk)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td>
                          <div>Screenshot of Bansos recipients</div>
                          <span className="font-size-11">
                            {`*additional documents for the category "Special Requirements for Public with Financial Disabilities""`}
                          </span>
                        </td>

                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_bansos)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default TabPreviewFormRequiredDocuments
