/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputTextArea from "components/Input/InputTextArea"
import InputEmail from "components/Input/InputEmail"
import InputNumber from "components/Input/InputNumber"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { canSubmitRegister, scrollTop } from "helpers/utils_helper"

const TabFormEmergencyContactInformation = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
  reset = false,
  onResetFalse = () => {},
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (reset) {
      setError("")
      onResetFalse()
    }
  }, [reset])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name_a:  dataDetail?.r03_name_a || "",
      address_a:  dataDetail?.r03_address_a || "",
      city_a:  dataDetail?.r03_city_a || "",
      province_a:  dataDetail?.r03_province_a || "",
      postal_code_a:  dataDetail?.r03_postal_code_a || "",
      home_phone_a:  dataDetail?.r03_home_phone_a || "",
      mobile_phone_a:  dataDetail?.r03_mobile_phone_a || "",
      email_a:  dataDetail?.r03_email_a || "",
      relationship_a:  dataDetail?.r03_relationship_a || "",

      name_b:  dataDetail?.r03_name_b || "",
      address_b:  dataDetail?.r03_bddress_b || "",
      city_b:  dataDetail?.r03_city_b || "",
      province_b:  dataDetail?.r03_province_b || "",
      postal_code_b:  dataDetail?.r03_postal_code_b || "",
      home_phone_b:  dataDetail?.r03_home_phone_b || "",
      mobile_phone_b:  dataDetail?.r03_mobile_phone_b || "",
      email_b:  dataDetail?.r03_email_b || "",
      relationship_b:  dataDetail?.r03_relationship_b || "",
    },

    onSubmit: values => {

      //if (!loading) postBeasiswaRegister(values)

      if (canSubmitRegister(dataDetail?.status)) {
        if (!loading) postBeasiswaRegister(values)
      } else {
        onNext()
      }
    },
  })

  const postBeasiswaRegister = async data => {
    try {
      setLoading(true)
      const response = await postAPIBeasiswaRegister(data, "03")
      setLoading(false)
      if (response.status === "SUCCESS") {
        onSuccess(response.message)
        onNext()
      } else {
        scrollTop()
        setError(response.message)
        
      }
    } catch (error) {
      setLoading(false)
      scrollTop()
      setError("Something went wrong, please try again")
     
    }
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <p className="card-title-desc">
          Provide details of two persons that we may contact in case of
          emergency
        </p>

        {/* FIRST NAME */}
        <div className="mb-3">
          <InputText
            label="Name"
            name="name_a"
            placeholder="Name"
            value={_formik.values.name_a || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <Row>
          <Col md={6}>
            {/* RESIDENTIAL ADDRESS */}
            <div className="mb-3">
              <InputTextArea
                label="Residential Address"
                name="address_a"
                placeholder="Residential Address"
                value={_formik.values.address_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
                rows={3}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* City */}
            <div className="mb-3">
              <InputText
                label="City"
                name="city_a"
                placeholder="City"
                value={_formik.values.city_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Province */}
            <div className="mb-3">
              <InputText
                label="Province"
                name="province_a"
                placeholder="Province"
                value={_formik.values.province_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* POSTAL CODE */}
            <div className="mb-3">
              <InputText
                label="Postal Code"
                name="postal_code_a"
                placeholder="Postal Code"
                value={_formik.values.postal_code_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            {/* HOME TELEPHONE */}
            <div className="mb-3">
              <InputText
                label="Home Telephone"
                name="home_phone_a"
                placeholder="Home Telephone"
                value={_formik.values.home_phone_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* EMAIL */}
            <div className="mb-3">
              <InputEmail
                label="Email"
                name="email_a"
                placeholder="Email"
                value={_formik.values.email_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* MOBILE TELEPHONE */}
            <div className="mb-3">
              <InputText
                label="Mobile Number"
                name="mobile_phone_a"
                placeholder="Mobile Number"
                value={_formik.values.mobile_phone_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* RELATIONSHIP TO YOU */}
            <div className="mb-3">
              <InputText
                label="Relationship to you"
                name="relationship_a"
                placeholder="Relationship to you"
                value={_formik.values.relationship_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <hr />

        {/* FIRST NAME */}
        <div className="mb-3">
          <InputText
            label="Name"
            name="name_b"
            placeholder="Name"
            value={_formik.values.name_b || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <Row>
          <Col md={6}>
            {/* RESIDENTIAL ADDRESS */}
            <div className="mb-3">
              <InputTextArea
                label="Residential Address"
                name="address_b"
                placeholder="Residential Address"
                value={_formik.values.address_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
                rows={3}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* City */}
            <div className="mb-3">
              <InputText
                label="City"
                name="city_b"
                placeholder="City"
                value={_formik.values.city_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* Province */}
            <div className="mb-3">
              <InputText
                label="Province"
                name="province_b"
                placeholder="Province"
                value={_formik.values.province_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* POSTAL CODE */}
            <div className="mb-3">
              <InputText
                label="Postal Code"
                name="postal_code_b"
                placeholder="Postal Code"
                value={_formik.values.postal_code_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            {/* HOME TELEPHONE */}
            <div className="mb-3">
              <InputText
                label="Home Telephone"
                name="home_phone_b"
                placeholder="Home Telephone"
                value={_formik.values.home_phone_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* EMAIL */}
            <div className="mb-3">
              <InputEmail
                label="Email"
                name="email_b"
                placeholder="Email"
                value={_formik.values.email_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* MOBILE TELEPHONE */}
            <div className="mb-3">
              <InputText
                label="Mobile Number"
                name="mobile_phone_b"
                placeholder="Mobile Number"
                value={_formik.values.mobile_phone_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* RELATIONSHIP TO YOU */}
            <div className="mb-3">
              <InputText
                label="Relationship to you"
                name="relationship_b"
                placeholder="Relationship to you"
                value={_formik.values.relationship_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <hr />

        <div className="d-flex justify-content-end">
          {onPrev && (
            <Link
              to="#"
              className={`btn btn-primary btn-block me-2 ${loading ? 'disabled' : ''}`}
              onClick={onPrev}
            >
              Previous
            </Link>
          )}

          <button className="btn btn-primary btn-block" type="submit">
            {loading ? <Spinner size="sm" /> : "Next"}
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TabFormEmergencyContactInformation
