/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputFile from "components/Input/InputFile"
import InputSingleSelect from "components/Input/InputSingleSelect"
import InputDateMask from "components/Input/InputDateMask"
import InputTextArea from "components/Input/InputTextArea"
import InputEmail from "components/Input/InputEmail"
import InputNumber from "components/Input/InputNumber"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { canSubmitRegister, scrollTop } from "helpers/utils_helper"
import ModalInfoPage11 from "../info-page11-modal"

const TabFormFieldOfStudy = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
  industrialSector = null,
  reset = false,
  onResetFalse = () => {},
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (reset) {
      setError("")
      onResetFalse()
    }
  }, [reset])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      industrial_sector: dataDetail?.r11_industrial_sector || "",
      code: dataDetail?.r11_code || "",
      field_study: dataDetail?.r11_field_study || "",
      specific_major: dataDetail?.r11_specific_major || "",
      reason: dataDetail?.r11_reason || "",
    },

    onSubmit: values => {
      console.log(values)
      if (canSubmitRegister(dataDetail?.status)) {
        if (!loading) postBeasiswaRegister(values)
      } else {
        onNext()
      }
    },
  })

  const postBeasiswaRegister = async data => {
    try {
      setLoading(true)
      const response = await postAPIBeasiswaRegister(data, "11")
      setLoading(false)
      if (response.status === "SUCCESS") {
        onSuccess(response.message)
        onNext()
      } else {
        scrollTop()
        setError(response.message)
        
      }
    } catch (error) {
      setLoading(false)
      scrollTop()
      setError("Something went wrong, please try again")
     
    }
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        <ModalInfoPage11
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          content={industrialSector || ""}
        />
        {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <p className="card-title-desc">
          For this mandatory
          section, refer to the BUMN Industrial Sector field of study
          descriptions found{" "}
          <Link to="#" onClick={() => setModalOpen(true)}>
            <strong style={{ cursor: "pointer" }}>here</strong>
          </Link>
          . If your area of study is not included, you may propose another area
          of study that is still RELATED TO the area of study as stated above.
          Please indicate the industrial sector and specific area of study that
          you would like to pursue.
        </p>

        <Row>
          <Col md={6}>
            {/* BUMN INDUSTRIAL SECTOR */}
            <div className="mb-3">
              <InputText
                label="BUMN Industrial Sector"
                name="industrial_sector"
                placeholder="BUMN Industrial Sector"
                value={_formik.values.industrial_sector || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* CODE */}
            <div className="mb-3">
              <InputText
                label="Code"
                name="code"
                placeholder="code"
                value={_formik.values.code || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* FIELD OF STUDY */}
            <div className="mb-3">
              <InputText
                label="Field of Study"
                name="field_study"
                placeholder="Field of Study"
                value={_formik.values.field_study || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* SPECIFIC MAJOR */}
            <div className="mb-3">
              <InputText
                label="Specific Major"
                name="specific_major"
                placeholder="Specific Major"
                value={_formik.values.specific_major || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        {/* REASONS */}
        <div className="mb-3">
          <InputTextArea
            label="In 150 words or less, describe the reason(s) why you chose this field of study."
            name="reason"
            placeholder=""
            value={_formik.values.reason || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <hr />

        <div className="d-flex justify-content-end">
          {onPrev && (
            <Link
              to="#"
              className={`btn btn-primary btn-block me-2 ${loading ? 'disabled' : ''}`}
              onClick={onPrev}
            >
              Previous
            </Link>
          )}

          <button className="btn btn-primary btn-block" type="submit">
            {loading ? <Spinner size="sm" /> : "Next"}
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TabFormFieldOfStudy
