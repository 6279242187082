import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Card, Alert, CardBody, Button, Form } from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import InputText from "components/Input/InputText"
import {
  postApiUpdatePassword,
  postApiUpdateProfile,
} from "helpers/BackendHelper/profile_backend"
import InputPassword from "components/Input/InputPassword"
import InputRadio from "components/Input/InputRadio"
import InputFile from "components/Input/InputFile"
import { scrollTop } from "helpers/utils_helper"

const CompChangeProfile = props => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [photo, setPhoto] = useState("")
  const [dataLocal, setDataLocal] = useState(null)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setDataLocal(obj)
      setname(obj.name)
      setPhoto(obj.photo_url)
      setemail(obj.email)
    }
  }, [])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: name || "",
      delete_photo: "no",
    },

    onSubmit: values => {
      scrollTop();
      updateProfile(values)
    },
  })

  const updateProfile = async data => {
    try {
      const response = await postApiUpdateProfile(data)
      if (response.status === "SUCCESS") {
        setSuccess("Profile berhasil diupdate, halaman akan otomatis memuat ulang")
        localStorage.setItem(
          "authUser",
          JSON.stringify({
            ...dataLocal,
            name: response?.name || dataLocal.name,
            photo_url: response?.photo_url || dataLocal.photo_url,
          })
        )

        setTimeout(() => {
          window.location.reload()
        }, 1500)
      } else {
        setrror(response.message)
        setTimeout(() => {
          setError("")
        }, 2000)
      }
    } catch (error) {
      setError("Something went wrong, please try again")
     
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

          {success && success ? (
            <Alert color="success">Success: {success}</Alert>
          ) : null}
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              _formik.handleSubmit()
              return false
            }}
          >
            <div className="form-group">
              <img
                className="avatar-lg header-profile-user cover-full mb-3"
                src={photo}
                alt="User Photo"
                style={{ objectPosition: "top" }}
              />

              {/* NAME */}
              <div className="mb-3">
                <InputText
                  label="Name"
                  name="name"
                  placeholder="Name"
                  value={_formik.values.name || ""}
                  invalid={
                    _formik.touched.first_name && _formik.errors.name
                      ? true
                      : false
                  }
                  onChange={_formik.handleChange}
                  onBlur={_formik.handleBlur}
                />
              </div>

              {/* RECENT PHOTO */}
              <div className="mb-3">
                <InputFile
                  label="Photo"
                  name="photo"
                  onChange={event => {
                    _formik.setFieldValue("photo", event.currentTarget.files[0])
                  }}
                  onBlur={_formik.handleBlur}
                />
              </div>

              <InputRadio
                label="Delete Photo"
                options={[
                  {
                    label: "Yes",
                    value: "yes",
                  },
                  {
                    label: "No",
                    value: "no",
                  },
                ]}
                name="delete_photo"
                value={_formik.values.delete_photo || ""}
                onChange={_formik.handleChange}
              />
            </div>
            <div className="text-center mt-4">
              <Button type="submit" color="danger">
                Update Profile
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default CompChangeProfile
