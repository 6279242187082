/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import {
  Alert,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  Row,
  Spinner,
  Table,
} from "reactstrap"
import InputFile from "components/Input/InputFile"
import { Link, useHistory } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { canSubmitRegister, scrollTop } from "helpers/utils_helper"

import SweetAlert from "react-bootstrap-sweetalert"

const TabFormRequiredDocuments = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
  reset = false,
  onResetFalse = () => {},
}) => {
  const history = useHistory()
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState(false)

  useEffect(() => {
    if (reset) {
      setError("")
      onResetFalse()
    }
  }, [reset])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      is_preview: "1",
      application_form: "",
      undergraduate_certificate: "",
      academic_transcript: "",
      photo: "",
      id_card: "",
      toefl_score: "",
      bumn_letter: "",
      reference_letter: "",
      acceptance_letter: "",
      award: "",
      publication: "",
      cv: "",
      kk: "",
      bansos: "",
    },

    onSubmit: values => {
      if (canSubmitRegister(dataDetail?.status)) {
        if (!loading) postBeasiswaRegister(values)
      } else {
        onNext()
      }
    },
  })

  const postBeasiswaRegister = async data => {
    try {
      setLoading(true)
      const response = await postAPIBeasiswaRegister(data, "15")
      setLoading(false)
      if (response.status === "SUCCESS") {
        onSuccess(response.message)
        if (response.do === "preview") onNext()
      } else {
        scrollTop()
        setError(response.message)
      }
    } catch (error) {
      setLoading(false)
      scrollTop()
      setError("Something went wrong, please try again")
    }
  }

  const previewFile = url => {
    if (url && url !== "") {
      window.open(url, "_target")
    } else {
      setInfo(true)
    }
  }

  return (
    <React.Fragment>
      {info ? (
        <SweetAlert
          title="Belum ada data silahkan pilih data lalu submit."
          onConfirm={() => {
            setInfo(false)
          }}
        />
      ) : null}
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <p className="card-title-desc mb-0">
          The complete application package must include:
        </p>

        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="mb-1">Mandatory Documents</CardTitle>
                <small className="form-text text-muted">
                  <strong>*allowed formats: JPG, PDF, ZIP</strong>
                </small>
                <div className="table-responsive">
                  <Table className="table table-bordered mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>
                          <div>Completed and signed application form</div>
                          <span className="font-size-11">
                            *Application form can be downloaded{" "}
                            <Link
                              to="https://beasiswabumn.jagoan.info/media/BUMN_Scholarship_Application_Form.pdf"
                              target={"_blank"}
                            >
                              <strong>here</strong>
                            </Link>
                          </span>
                        </td>
                        <td className="w-35">
                          <InputFile
                            className="form-control form-control-sm"
                            name="application_form"
                            onChange={event => {
                              _formik.setFieldValue(
                                "application_form",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={_formik.handleBlur}
                          />
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_application_form_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Certified Undergraduate Certificate</td>
                        <td className="w-35">
                          <InputFile
                            className="form-control form-control-sm"
                            name="undergraduate_certificate"
                            onChange={event => {
                              _formik.setFieldValue(
                                "undergraduate_certificate",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={_formik.handleBlur}
                          />
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(
                                dataDetail?.r15_undergraduate_certificate_url
                              )
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Certified Undergraduate Academic Transcript</td>
                        <td className="w-35">
                          <InputFile
                            className="form-control form-control-sm"
                            name="academic_transcript"
                            onChange={event => {
                              _formik.setFieldValue(
                                "academic_transcript",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={_formik.handleBlur}
                          />
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(
                                dataDetail?.r15_academic_transcript_url
                              )
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>Recent photographs (4 X 6, B/W or color)</td>
                        <td className="w-35">
                          <InputFile
                            className="form-control form-control-sm"
                            name="photo"
                            onChange={event => {
                              _formik.setFieldValue(
                                "photo",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={_formik.handleBlur}
                          />
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td>
                          Copy of valid national ID card (KTP or Passport)
                        </td>
                        <td className="w-35">
                          <InputFile
                            className="form-control form-control-sm"
                            name="id_card"
                            onChange={event => {
                              _formik.setFieldValue(
                                "id_card",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={_formik.handleBlur}
                          />
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_id_card_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td>
                          Valid TOEFL ITP or TOEFL iBT or IELTS or other
                          language proficiency score (test date only from
                          September 2020 and afterwards)
                        </td>
                        <td className="w-35">
                          <InputFile
                            className="form-control form-control-sm"
                            name="toefl_score"
                            onChange={event => {
                              _formik.setFieldValue(
                                "toefl_score",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={_formik.handleBlur}
                          />
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_toefl_score_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">7</th>
                        <td>Original BUMN Statement Letters</td>
                        <td className="w-35">
                          <InputFile
                            className="form-control form-control-sm"
                            name="bumn_letter"
                            onChange={event => {
                              _formik.setFieldValue(
                                "bumn_letter",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={_formik.handleBlur}
                          />
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_bumn_letter_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">8</th>
                        <td>Original Reference Letters</td>
                        <td className="w-35">
                          <InputFile
                            className="form-control form-control-sm"
                            name="reference_letter"
                            onChange={event => {
                              _formik.setFieldValue(
                                "reference_letter",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={_formik.handleBlur}
                          />
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_reference_letter_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="mb-1">Supplementary Documents</CardTitle>
                <small className="form-text text-muted">
                  <strong>*allowed formats: JPG, PDF, ZIP</strong>
                </small>
                <div className="table-responsive">
                  <Table className="table table-bordered mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Copy Valid Letter of Acceptance</td>
                        <td className="w-35">
                          <InputFile
                            className="form-control form-control-sm"
                            name="acceptance_letter"
                            onChange={event => {
                              _formik.setFieldValue(
                                "acceptance_letter",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={_formik.handleBlur}
                          />
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_acceptance_letter_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Copy of certificates and awards</td>
                        <td className="w-35">
                          <InputFile
                            className="form-control form-control-sm"
                            name="award"
                            onChange={event => {
                              _formik.setFieldValue(
                                "award",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={_formik.handleBlur}
                          />
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_award_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>
                          Copy of article, journal, research or cover page of
                          your publication, etc.
                        </td>
                        <td className="w-35">
                          <InputFile
                            className="form-control form-control-sm"
                            name="publication"
                            onChange={event => {
                              _formik.setFieldValue(
                                "publication",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={_formik.handleBlur}
                          />
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_publication_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>Recent Curriculum Vitae (in English)</td>
                        <td className="w-35">
                          <InputFile
                            className="form-control form-control-sm"
                            name="cv"
                            onChange={event => {
                              _formik.setFieldValue(
                                "cv",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={_formik.handleBlur}
                          />
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_cv_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td>
                          <div>Family Identity Card (Kartu Keluarga)</div>
                          <span className="font-size-11">
                            {`*additional documents for the category "Special Requirements for Public with Financial Disabilities"`}
                          </span>
                        </td>
                        <td className="w-35">
                          <InputFile
                            className="form-control form-control-sm"
                            name="kk"
                            onChange={event => {
                              _formik.setFieldValue(
                                "kk",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={_formik.handleBlur}
                          />
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_kk_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td>
                          <div>Screenshot of Bansos recipients</div>
                          <span className="font-size-11">
                            {`*additional documents for the category "Special Requirements for Public with Financial Disabilities""`}
                          </span>
                        </td>
                        <td className="w-35">
                          <InputFile
                            className="form-control form-control-sm"
                            name="bansos"
                            onChange={event => {
                              _formik.setFieldValue(
                                "bansos",
                                event.currentTarget.files[0]
                              )
                            }}
                            onBlur={_formik.handleBlur}
                          />
                        </td>
                        <td>
                          <Link
                            to="#"
                            className={`btn btn-primary btn-block btn-sm`}
                            onClick={() => {
                              previewFile(dataDetail?.r15_bansos_url)
                            }}
                          >
                            Preview
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <hr />

        <div className="d-flex justify-content-end">
          {onPrev && (
            <Link
              to="#"
              className={`btn btn-primary btn-block me-2 ${
                loading ? "disabled" : ""
              }`}
              onClick={onPrev}
            >
              Previous
            </Link>
          )}

          <button className="btn btn-primary btn-block" type="submit">
            {loading ? <Spinner size="sm" /> : "Preview"}
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TabFormRequiredDocuments
