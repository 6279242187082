/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { Alert, Form, Spinner } from "reactstrap"
import InputTextArea from "components/Input/InputTextArea"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { canSubmitRegister, scrollTop } from "helpers/utils_helper"

const TabFormPhysicalDisabilities = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
  reset = false,
  onResetFalse = () => {},
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()

   useEffect(() => {
    if (reset) {
      setError("")
      onResetFalse()
    }
  }, [reset])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      disability: dataDetail?.r02_disability || ""
    },
    // validationSchema: Yup.object({
    //   disability: Yup.string().required("Please describe your disability"),
    // }),
    onSubmit: values => {
      if (canSubmitRegister(dataDetail?.status)) {
        if (!loading) postBeasiswaRegister(values)
      } else {
        onNext()
      }
    },
  })

  const postBeasiswaRegister = async data => {
    try {
      setLoading(true)
      const response = await postAPIBeasiswaRegister(data, "02")
      setLoading(false)
      if (response.status === "SUCCESS") {
        onSuccess(response.message)
        onNext()
      } else {
        scrollTop()
        setError(response.message)
        
      }
    } catch (error) {
      setLoading(false)
      scrollTop()
      setError("Something went wrong, please try again")
     
    }
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <p className="card-title-desc">
          Please describe your disability(ies) and indicate what support, if
          any, you need from Yayasan BUMN Scholarship in order to be successful
          in your future studies. (e.g. wheel chair, special communication
          devices, braille computer, other devices or assistance)
        </p>

        {/* PHYSICAL DISABILITY */}
        <div className="mb-3">
          <InputTextArea
            name="disability"
            placeholder="Physical Disability"
            value={_formik.values.disability || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <hr />

        <div className="d-flex justify-content-end">
          {onPrev && (
            <Link
              to="#"
              className={`btn btn-primary btn-block me-2 ${loading ? 'disabled' : ''}`}
              onClick={loading ? () => {} : onPrev}
            >
              Previous
            </Link>
          )}

          <button className="btn btn-primary btn-block" type="submit">
            {loading ? <Spinner size="sm" /> : "Next"}
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TabFormPhysicalDisabilities
