/* eslint-disable react/prop-types */

import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useState } from "react"
import { Alert, Form, Spinner } from "reactstrap"
import InputTextArea from "components/Input/InputTextArea"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { scrollTop } from "helpers/utils_helper"
import DivText from "components/Input/DivText"

const TabPreviewFormCommunityServiceActivities = ({
  dataDetail = null,
}) => {
  return (
    <React.Fragment>
      <Form>
      <h4 className="mb-3 font-size-18">{"COMMUNITY SERVICE ACTIVITIES"}</h4>

        {/* DESCRIBE ROLE */}
        <div className="mb-3">
          <DivText
            label="Describe your role in community service activities or civil society organizations"
            content={dataDetail?.r08_community_service || "-"}
          />
        </div>

        <hr />
      </Form>
    </React.Fragment>
  )
}

export default TabPreviewFormCommunityServiceActivities
