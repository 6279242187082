/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputFile from "components/Input/InputFile"
import InputSingleSelect from "components/Input/InputSingleSelect"
import InputDateMask from "components/Input/InputDateMask"
import InputTextArea from "components/Input/InputTextArea"
import InputEmail from "components/Input/InputEmail"
import InputNumber from "components/Input/InputNumber"
import InputCheckbox from "components/Input/InputCheckbox"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { canSubmitRegister, scrollTop } from "helpers/utils_helper"

const TabFormPublications = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
  reset = false,
  onResetFalse = () => {},
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (reset) {
      setError("")
      onResetFalse()
    }
  }, [reset])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      publication_title_a: dataDetail?.r09_publication_title_a || "",
      publication_date_a: dataDetail !== null && dataDetail?.r09_publication_date_a !== "0000-00-00" ? dataDetail?.r09_publication_date_a : "",
      publication_name_a: dataDetail?.r09_publication_name_a || "",
      co_author_a: dataDetail?.r09_co_author_a || "",

      publication_title_b: dataDetail?.r09_publication_title_b || "",
      publication_date_b: dataDetail !== null && dataDetail?.r09_publication_date_b !== "0000-00-00" ? dataDetail?.r09_publication_date_b : "",
      publication_name_b: dataDetail?.r09_publication_name_b || "",
      co_author_b: dataDetail?.r09_co_author_b || "",
    },

    onSubmit: values => {
      values = {
        ...values,
        publication_date_a: values.publication_date_a,
        publication_date_b: values.publication_date_b,
      }

      if (canSubmitRegister(dataDetail?.status)) {
        if (!loading) postBeasiswaRegister(values)
      } else {
        onNext()
      }
    },
  })

  const postBeasiswaRegister = async data => {
    try {
      setLoading(true)
      const response = await postAPIBeasiswaRegister(data, "09")
      setLoading(false)
      if (response.status === "SUCCESS") {
        onSuccess(response.message)
        onNext()
      } else {
        scrollTop()
        setError(response.message)
        
      }
    } catch (error) {
      setLoading(false)
      scrollTop()
      setError("Something went wrong, please try again")
     
    }
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <p className="card-title-desc">
          List any articles that you authored which have been published and are
          relevant to your application. Attach a copy of the article(s) with the
          cover page of the publication(s). Include additional examples or
          details on an additional page as necessary.
        </p>

        {/* Title of Article */}
        <div className="mb-3">
          <InputText
            label="Title of Article"
            name="publication_title_a"
            placeholder="Title of Article"
            value={_formik.values.publication_title_a || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <Row>
          <Col md={6}>
            {/* Publication Date */}
            <div className="mb-3">
              <InputDateMask
                label="Publication Date"
                name="publication_date_a"
                placeholder="Publication Date (DD/MM/YYYY)"
                value={_formik.values.publication_date_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Name of Publication */}
            <div className="mb-3">
              <InputText
                label="Name of Publication"
                name="publication_name_a"
                placeholder="Name of Publication"
                value={_formik.values.publication_name_a || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        {/* Co-author(s) */}
        <div className="mb-3">
          <InputText
            label="Co-author(s)"
            name="co_author_a"
            placeholder="Co-author(s)"
            infoMsg="(if applicable)"
            value={_formik.values.co_author_a || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <hr />

        {/* Title of Article */}
        <div className="mb-3">
          <InputText
            label="Title of Article"
            name="publication_title_b"
            placeholder="Title of Article"
            value={_formik.values.publication_title_b || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <Row>
          <Col md={6}>
            {/* Publication Date */}
            <div className="mb-3">
              <InputDateMask
                label="Publication Date"
                name="publication_date_b"
                placeholder="Publication Date (DD/MM/YYYY)"
                value={_formik.values.publication_date_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Name of Publication */}
            <div className="mb-3">
              <InputText
                label="Name of Publication"
                name="publication_name_b"
                placeholder="Name of Publication"
                value={_formik.values.publication_name_b || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        {/* Co-author(s) */}
        <div className="mb-3">
          <InputText
            label="Co-author(s)"
            name="co_author_b"
            placeholder="Co-author(s)"
            infoMsg="(if applicable)"
            value={_formik.values.co_author_b || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <hr />

        <div className="d-flex justify-content-end">
          {onPrev && (
            <Link
              to="#"
              className={`btn btn-primary btn-block me-2 ${loading ? 'disabled' : ''}`}
              onClick={onPrev}
            >
              Previous
            </Link>
          )}

          <button className="btn btn-primary btn-block" type="submit">
            {loading ? <Spinner size="sm" /> : "Next"}
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TabFormPublications
