/* eslint-disable react/prop-types */
import React, { useState } from "react"
import { Input, FormFeedback, Label } from "reactstrap"

const InputRadio = ({
  name,
  label,
  infoMsg,
  options,
  value,
  onChange = () => {},
}) => {
  return (
    <React.Fragment>
      <Label className="form-label">{label}</Label>
      <div className="d-flex flex-wrap">
        {options.map((option, index) => {
          return (
            <div key={index} className="form-check me-3">
              <input
                className="form-check-input"
                type="radio"
                name={name}
                onChange={onChange}
                defaultChecked={value === option.value}
                value={option.value}
              />
              <label className="form-check-label" htmlFor="exampleRadios2">
                {option.label}
              </label>
            </div>
          )
        })}
      </div>
      {infoMsg && <small className="form-text text-muted">{infoMsg}</small>}
    </React.Fragment>
  )
}

export default InputRadio
