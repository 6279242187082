import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { isMobile } from "react-device-detect"

import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TabFormPersonalInformation from "./tabs/tab-form-personal-information"
import TabFormPhysicalDisabilities from "./tabs/tab-form-physical-disabilities"
import TabFormEmergencyContactInformation from "./tabs/tab-form-emergency-contact-information"
import TabFormEnglishLanguageProficiency from "./tabs/tab-form-english-language-proficiency"
import TabFormEducationalBackground from "./tabs/tab-form-educational-background"
import TabFormScholarshipHistory from "./tabs/tab-form-scholarship-history"
import TabFormCommunityServiceActivities from "./tabs/tab-form-community-service-activities"
import TabFormAwards from "./tabs/tab-form-awards"
import TabFormFieldOfStudy from "./tabs/tab-form-field-of-study"
import TabFormEssays from "./tabs/tab-form-essays"
import TabFormReferenceSummary from "./tabs/tab-form-reference-summary"
import TabFormRequiredDocuments from "./tabs/tab-form-required-documents"
import TabFormEmploymentHistory from "./tabs/tab-form-employment-history"
import TabFormPublications from "./tabs/tab-form-publications"
import TabFormProposedUniversity from "./tabs/tab-form-proposed_university"
import { getAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { scrollIntoView, scrollTop } from "helpers/utils_helper"
import TabPreview from "./preview/tab-preview"

const FormPengajuanBeasiswa = () => {
  const [activeTabVertical, setoggleTabVertical] = useState(1)
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  const [dataDetail, setDataDetail] = useState(null)
  const [reset, setReset] = useState(true)

  const [industrialSector, setIndustrialSector] = useState(null)

  useEffect(() => {
    getBeasiswaRegister()
  }, [])

  useEffect(() => {
    if (isMobile)
      if (
        document
          .getElementById(`tab_${activeTabVertical}`)
          .classList.contains("d-none")
      )
        document
          .getElementById(`tab_${activeTabVertical}`)
          .classList.remove("d-none")
  }, [activeTabVertical])

  const getBeasiswaRegister = async data => {
    try {
      const response = await getAPIBeasiswaRegister()
      if (response.status === "SUCCESS") {
        setDataDetail(response.data[0])
        setIndustrialSector(response.industrial_sector)
      } else {
      }
    } catch (error) {}
  }

  const onSuccess = message => {
    toast.success(message ? message : "Input Data Berhasil", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const tabsMenu = [
    {
      index: 1,
      title: "PERSONAL INFORMATION",
      comp: (
        <TabFormPersonalInformation
          onPrev={null}
          onNext={() => toggleTabVertical(2)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          reset={reset}
          onResetFalse={() => setReset(false)}
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 2,
      title: "PHYSICAL DISABILITIES",
      subtitle: "(if applicable)",
      comp: (
        <TabFormPhysicalDisabilities
          onPrev={() => toggleTabVertical(1)}
          onNext={() => toggleTabVertical(3)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          reset={reset}
          onResetFalse={() => setReset(false)}
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 3,
      title: "EMERGENCY CONTACT INFORMATION",
      comp: (
        <TabFormEmergencyContactInformation
          onPrev={() => toggleTabVertical(2)}
          onNext={() => toggleTabVertical(4)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          reset={reset}
          onResetFalse={() => setReset(false)}
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 4,
      title: "ENGLISH LANGUAGE PROFICIENCY",
      comp: (
        <TabFormEnglishLanguageProficiency
          onPrev={() => toggleTabVertical(3)}
          onNext={() => toggleTabVertical(5)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          reset={reset}
          onResetFalse={() => setReset(false)}
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 5,
      title: "EDUCATIONAL BACKGROUND",
      comp: (
        <TabFormEducationalBackground
          onPrev={() => toggleTabVertical(4)}
          onNext={() => toggleTabVertical(6)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          reset={reset}
          onResetFalse={() => setReset(false)}
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 6,
      title: "SCHOLARSHIP HISTORY",
      comp: (
        <TabFormScholarshipHistory
          onPrev={() => toggleTabVertical(5)}
          onNext={() => toggleTabVertical(7)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          reset={reset}
          onResetFalse={() => setReset(false)}
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 7,
      title: "EMPLOYMENT HISTORY",
      comp: (
        <TabFormEmploymentHistory
          onPrev={() => toggleTabVertical(6)}
          onNext={() => toggleTabVertical(8)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          reset={reset}
          onResetFalse={() => setReset(false)}
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 8,
      title: "COMMUNITY SERVICE ACTIVITIES",
      comp: (
        <TabFormCommunityServiceActivities
          onPrev={() => toggleTabVertical(7)}
          onNext={() => toggleTabVertical(9)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          reset={reset}
          onResetFalse={() => setReset(false)}
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 9,
      title: "PUBLICATIONS",
      comp: (
        <TabFormPublications
          onPrev={() => toggleTabVertical(8)}
          onNext={() => toggleTabVertical(10)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          reset={reset}
          onResetFalse={() => setReset(false)}
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 10,
      title: "AWARDS",
      comp: (
        <TabFormAwards
          onPrev={() => toggleTabVertical(9)}
          onNext={() => toggleTabVertical(11)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          reset={reset}
          onResetFalse={() => setReset(false)}
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 11,
      title: "FIELD OF STUDY",
      comp: (
        <TabFormFieldOfStudy
          onPrev={() => toggleTabVertical(10)}
          onNext={() => toggleTabVertical(12)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          industrialSector={industrialSector}
          reset={reset}
          onResetFalse={() => setReset(false)}
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 12,
      title: "PROPOSED UNIVERSITY",
      comp: (
        <TabFormProposedUniversity
          onPrev={() => toggleTabVertical(11)}
          onNext={() => toggleTabVertical(13)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          reset={reset}
          onResetFalse={() => setReset(false)}
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 13,
      title: "ESSAYS",
      comp: (
        <TabFormEssays
          onPrev={() => toggleTabVertical(12)}
          onNext={() => toggleTabVertical(14)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          reset={reset}
          onResetFalse={() => setReset(false)}
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 14,
      title: "REFERENCE SUMMARY",
      comp: (
        <TabFormReferenceSummary
          onPrev={() => toggleTabVertical(13)}
          onNext={() => toggleTabVertical(15)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          reset={reset}
          onResetFalse={() => setReset(false)}
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 15,
      title: "REQUIRED DOCUMENTS",
      comp: (
        <TabFormRequiredDocuments
          onPrev={() => toggleTabVertical(14)}
          onNext={() => toggleTabVertical(16)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          reset={reset}
          onResetFalse={() => setReset(false)}
          lastPage
        />
      ),
      display: isMobile ? false : true,
    },
    {
      index: 16,
      title: "PREVIEW",
      comp: (
        // <TabFormRequiredDocuments
        //   onPrev={() => toggleTabVertical(15)}
        //   onNext={() => getBeasiswaRegister()}
        //   onSuccess={onSuccess}
        //   dataDetail={dataDetail}
        //   reset={reset}
        //   onResetFalse={() => setReset(false)}
        //   lastPage
        // />
        <TabPreview
          onPrev={() => toggleTabVertical(15)}
          onSuccess={onSuccess}
        />
      ),
      display: isMobile ? false : true,
    },
  ]

  function toggleTabVertical(tab, type) {
    if (activeTabVertical !== tab) {
      if (isMobile) {
        if (
          !document
            .getElementById(`tab_${activeTabVertical}`)
            .classList.contains("d-none")
        )
          document
            .getElementById(`tab_${activeTabVertical}`)
            .classList.add("d-none")
      }

      setReset(true)
      scrollTop()

      var modifiedSteps = [...passedStepsVertical, tab]

      setoggleTabVertical(tab)
      setPassedStepsVertical(modifiedSteps)
    }
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="page-content">
        <MetaTags>
          <title>Submission Form | Beasiswa BUMN</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs
            title="Registration"
            breadcrumbItem="Submission Form"
            titleMenu="Submission Form"
          />

          <React.Fragment>
            {/* DESKTOP */}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="box-wizard-mobile">
                    <div className="vertical-wizard wizard clearfix vertical">
                      <div className="steps clearfix">
                        <ul>
                          {tabsMenu.map((tabmenu, index) => {
                            return (
                              <NavItem
                                id={`tab_${tabmenu.index}`}
                                key={index}
                                className={`${classnames({
                                  current: activeTabVertical === index + 1,
                                })} ${tabmenu.display ? "" : "d-none"}`}
                              >
                                <NavLink
                                  className={classnames({
                                    active: activeTabVertical === index + 1,
                                  })}
                                  onClick={() => {
                                    if (index + 1 !== 16)
                                      toggleTabVertical(index + 1)
                                  }}
                                >
                                  <div className="d-flex">
                                    <div>
                                      <span className="number">
                                        {index + 1}
                                      </span>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center">
                                      <span> {tabmenu.title}</span>
                                      <span className="font-size-12">
                                        {" "}
                                        {tabmenu.subtitle}
                                      </span>
                                    </div>
                                  </div>
                                </NavLink>
                              </NavItem>
                            )
                          })}
                        </ul>
                      </div>
                      <div
                        className="content clearfix"
                        id="content-register-beasiswa"
                      >
                        <TabContent
                          activeTab={activeTabVertical}
                          className="body"
                        >
                          {tabsMenu.map((tabmenu, index) => {
                            return (
                              <TabPane key={index} tabId={index + 1}>
                                {tabmenu.comp}
                              </TabPane>
                            )
                          })}
                        </TabContent>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormPengajuanBeasiswa
