/* eslint-disable react/prop-types */

import React, { useState } from "react"
import { Form } from "reactstrap"
import DivText from "components/Input/DivText"

const TabPreviewFormPhysicalDisabilities = ({
  dataDetail = null,
}) => {
  return (
    <React.Fragment>
      <Form>

        <h4 className="mb-3 font-size-18">{"PHYSICAL DISABILITIES(if applicable)"}</h4>

        {/* PHYSICAL DISABILITY */}
        <div className="mb-3">
          <DivText
            label="Physical Disability"
            content={dataDetail?.r02_disability || "-"}
          />
        </div>

        <hr />
      </Form>
    </React.Fragment>
  )
}

export default TabPreviewFormPhysicalDisabilities
