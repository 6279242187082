/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputFile from "components/Input/InputFile"
import InputSingleSelect from "components/Input/InputSingleSelect"
import InputDateMask from "components/Input/InputDateMask"
import InputTextArea from "components/Input/InputTextArea"
import InputEmail from "components/Input/InputEmail"
import InputNumber from "components/Input/InputNumber"
import InputCheckbox from "components/Input/InputCheckbox"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { scrollTop, swapFormatedDate } from "helpers/utils_helper"
import InputRadio from "components/Input/InputRadio"
import DivText from "components/Input/DivText"

const TabPreviewFormEnglishLanguageProficiency = ({ dataDetail = null }) => {
  return (
    <React.Fragment>
      <Form>
        <h4 className="mb-3 font-size-18">{"ENGLISH LANGUAGE PROFICIENCY"}</h4>
        <Row>
          <Col md={6}>
            {/* Type of test */}
            <DivText
              label="Type of test"
              content={dataDetail?.r04_test_type || "-"}
            />

            {/* DATE TEST TAKEN */}
            <div className="mb-3">
              <DivText
                label="Date Test Taken"
                content={dataDetail?.r04_test_date || "-"}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* OVERALL SCORE */}
            <div className="mb-3">
              <DivText
                label="Overall Score"
                content={dataDetail?.r04_test_score || "-"}
              />
            </div>

            {/* TEST LOCATION */}
            <div className="mb-3">
              <DivText
                label="Test Location"
                content={dataDetail?.r04_test_location || "-"}
              />
            </div>
          </Col>
        </Row>

        <hr />
      </Form>
    </React.Fragment>
  )
}

export default TabPreviewFormEnglishLanguageProficiency
