/* eslint-disable react/prop-types */

import React, { useState } from "react"
import { Alert, Col, Form, Modal, ModalBody, Row, Spinner } from "reactstrap"
import parse from "html-react-parser"

const ModalInfoPage11 = ({
  isOpen = false,
  onClose = () => {},
  content = "",
}) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered={true}>
      <ModalBody>{parse(content) || ""}</ModalBody>
    </Modal>
  )
}

export default ModalInfoPage11
