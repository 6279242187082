/* eslint-disable react/prop-types */
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Card, Alert, CardBody, Button, Form } from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import InputText from "components/Input/InputText"
import { postApiUpdateEmail } from "helpers/BackendHelper/profile_backend"
import InputEmail from "components/Input/InputEmail"

const CompChangeEmail = ({
  email=""
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: email || "",
    },

    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Email"),
    }),

    onSubmit: values => {
      updateEmail(values)
    },
  })

  const updateEmail = async data => {
    try {
      const response = await postApiUpdateEmail(data)
      if (response.status === "SUCCESS") {
        setSuccess("Email berhasil diupdate")
        setTimeout(() => {
          setSuccess("")
        }, 2000)
      } else {
        setError(response.message)
        setTimeout(() => {
          setError("")
        }, 2000)
      }
    } catch (error) {
      setError("Something went wrong, please try again")
     
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

          {success && success ? (
            <Alert color="success">Success: {success}</Alert>
          ) : null}
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              _formik.handleSubmit()
              return false
            }}
          >
            <div className="form-group">
              {/* EMAIL */}
              <div className="mb-3">
                <InputEmail
                  label="Email"
                  name="email"
                  placeholder=""
                  value={_formik.values.email || ""}
                  onChange={_formik.handleChange}
                  onBlur={_formik.handleBlur}
                  invalid={
                    _formik.touched.email && _formik.errors.email ? true : false
                  }
                  errorMsg={_formik.errors.email}
                />
              </div>
            </div>
            <div className="text-center mt-4">
              <Button type="submit" color="danger">
                Update Email
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default CompChangeEmail
