/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useState } from "react"
import { Alert, Form, Spinner } from "reactstrap"
import InputTextArea from "components/Input/InputTextArea"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { scrollTop } from "helpers/utils_helper"
import DivText from "components/Input/DivText"

const TabPreviewFormAwards = ({ dataDetail = null }) => {
  return (
    <React.Fragment>
      <Form>
        <h4 className="mb-3 font-size-18">{"AWARDS"}</h4>

        {/* AWARDS */}
        <div className="mb-3">
          <DivText label="List Awards" content={dataDetail?.r10_award || "-"} />
        </div>

        <hr />
      </Form>
    </React.Fragment>
  )
}

export default TabPreviewFormAwards
