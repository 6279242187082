/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputTextArea from "components/Input/InputTextArea"
import InputEmail from "components/Input/InputEmail"
import InputNumber from "components/Input/InputNumber"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { scrollTop } from "helpers/utils_helper"
import DivText from "components/Input/DivText"

const TabPreviewFormEmergencyContactInformation = ({ dataDetail = null }) => {
  return (
    <React.Fragment>
      <Form>
        <h4 className="mb-3 font-size-18">{"EMERGENCY CONTACT INFORMATION"}</h4>

        {/* FIRST NAME */}
        <div className="mb-3">
          <DivText label="Name" content={dataDetail?.r03_name_a || "-"} />
        </div>

        <Row>
          <Col md={6}>
            {/* RESIDENTIAL ADDRESS */}
            <div className="mb-3">
              <DivText
                label="Residential Address"
                content={dataDetail?.r03_address_a || "-"}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* City */}
            <div className="mb-3">
              <DivText label="City" content={dataDetail?.r03_city_a || "-"} />
            </div>

            {/* Province */}
            <div className="mb-3">
              <DivText
                label="Province"
                content={dataDetail?.r03_province_a || "-"}
              />
            </div>

            {/* POSTAL CODE */}
            <div className="mb-3">
              <DivText
                label="Postal Code"
                content={dataDetail?.r03_postal_code_a || "-"}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            {/* HOME TELEPHONE */}
            <div className="mb-3">
              <DivText
                label="Home Telephone"
                content={dataDetail?.r03_home_phone_a || "-"}
              />
            </div>

            {/* EMAIL */}
            <div className="mb-3">
              <DivText label="Email" content={dataDetail?.r03_email_a || "-"} />
            </div>
          </Col>
          <Col md={6}>
            {/* MOBILE TELEPHONE */}
            <div className="mb-3">
              <DivText
                label="Mobile Number"
                content={dataDetail?.r03_mobile_phone_a || "-"}
              />
            </div>

            {/* RELATIONSHIP TO YOU */}
            <div className="mb-3">
              <DivText
                label="Relationship to you"
                content={dataDetail?.r03_relationship_a || "-"}
              />
            </div>
          </Col>
        </Row>

        <hr />

        {/* FIRST NAME */}
        <div className="mb-3">
          <DivText label="Name" content={dataDetail?.r03_name_b || "-"} />
        </div>

        <Row>
          <Col md={6}>
            {/* RESIDENTIAL ADDRESS */}
            <div className="mb-3">
              <DivText
                label="Residential Address"
                content={dataDetail?.r03_address_b || "-"}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* City */}
            <div className="mb-3">
              <DivText label="City" content={dataDetail?.r03_city_b || "-"} />
            </div>

            {/* Province */}
            <div className="mb-3">
              <DivText
                label="Province"
                content={dataDetail?.r03_province_b || "-"}
              />
            </div>

            {/* POSTAL CODE */}
            <div className="mb-3">
              <DivText
                label="Postal Code"
                content={dataDetail?.r03_postal_code_b || "-"}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            {/* HOME TELEPHONE */}
            <div className="mb-3">
              <DivText
                label="Home Telephone"
                content={dataDetail?.r03_home_phone_b || "-"}
              />
            </div>

            {/* EMAIL */}
            <div className="mb-3">
              <DivText label="Email" content={dataDetail?.r03_email_b || "-"} />
            </div>
          </Col>
          <Col md={6}>
            {/* MOBILE TELEPHONE */}
            <div className="mb-3">
              <DivText
                label="Mobile Number"
                content={dataDetail?.r03_mobile_phone_b || "-"}
              />
            </div>

            {/* RELATIONSHIP TO YOU */}
            <div className="mb-3">
              <DivText
                label="Relationship to you"
                content={dataDetail?.r03_relationship_b || "-"}
              />
            </div>
          </Col>
        </Row>

        <hr />
      </Form>
    </React.Fragment>
  )
}

export default TabPreviewFormEmergencyContactInformation
