/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import {
  Alert,
  Col,
  Form,
  Row,
  Spinner
} from "reactstrap"
import InputFile from "components/Input/InputFile"
import InputSingleSelect from "components/Input/InputSingleSelect"
import InputDateMask from "components/Input/InputDateMask"
import InputTextArea from "components/Input/InputTextArea"
import InputEmail from "components/Input/InputEmail"
import InputNumber from "components/Input/InputNumber"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { canSubmitRegister, scrollTop } from "helpers/utils_helper"

const TabFormEssays = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
  reset = false,
  onResetFalse = () => {},
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (reset) {
      setError("")
      onResetFalse()
    }
  }, [reset])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      essay_a: dataDetail?.r13_essay_a || "",
      essay_b: dataDetail?.r13_essay_b || "",
      essay_c: dataDetail?.r13_essay_c || "",
      essay_d: dataDetail?.r13_essay_d || "",
      essay_e: dataDetail?.r13_essay_e || "",
      essay_f: dataDetail?.r13_essay_f || "",
    },
    
    onSubmit: values => {
      if (canSubmitRegister(dataDetail?.status)) {
        if (!loading) postBeasiswaRegister(values)
      } else {
        onNext()
      }
    },
  })

  const postBeasiswaRegister = async data => {
    try {
      setLoading(true)
      const response = await postAPIBeasiswaRegister(data, "13")
      setLoading(false)
      if (response.status === "SUCCESS") {
        onSuccess(response.message);
        onNext();
      } else {
         scrollTop();
 setError(response.message)
        
      }
    } catch (error) {
      setLoading(false)
       scrollTop();
      setError("Something went wrong, please try again")
     
    }
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
         {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <p className="card-title-desc">
          Please answer each of the following questions in essay form using 150
          words or less.
        </p>

        {/* A */}
        <div className="mb-3">
          <InputTextArea
            name="essay_a"
            label="a. Briefly tell Yayasan BUMN about yourself and comment on your perception of your strengths and weaknesses."
            value={_formik.values.essay_a || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        {/* B */}
        <div className="mb-3">
          <InputTextArea
            name="essay_b"
            label="b. What challenges have you overcome in pursuing your professional development? What insights, perspectives, or skills have you gained in overcoming them?"
            value={_formik.values.essay_b || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        {/* C */}
        <div className="mb-3">
          <InputTextArea
            name="essay_c"
            label="c. Please describe an example where you demonstrated leadership skills in your work life or within your community."
            value={_formik.values.essay_c || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        {/* D */}
        <div className="mb-3">
          <InputTextArea
            name="essay_d"
            label="d. Students face many challenges. Please cite two anticipated challenges that most concern you about studying abroad and explain how you plan to address the challenges."
            value={_formik.values.essay_d || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        {/* E */}
        <div className="mb-3">
          <InputTextArea
            name="essay_e"
            label="e. Discuss a topic that you are considering for further research during your Master's studies."
            value={_formik.values.essay_e || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        {/* F */}
        <div className="mb-3">
          <InputTextArea
            name="essay_f"
            label="f. Discuss the most important current issues in your professional field. You may choose to answer in a general sense or focus on one or more issues that apply most directly to the context in which you work. Response to this question may be 250 words or less."
            value={_formik.values.essay_f || ""}
            onChange={_formik.handleChange}
            onBlur={_formik.handleBlur}
          />
        </div>

        <hr />

        <div className="d-flex justify-content-end">
          {onPrev && (
            <Link
              to="#"
              className={`btn btn-primary btn-block me-2 ${loading ? 'disabled' : ''}`}
              onClick={onPrev}
            >
              Previous
            </Link>
          )}

          <button className="btn btn-primary btn-block" type="submit">
            {loading ?  <Spinner size="sm" /> : "Next"}
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TabFormEssays
