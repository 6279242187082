/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputDateMask from "components/Input/InputDateMask"
import InputTextArea from "components/Input/InputTextArea"
import InputNumber from "components/Input/InputNumber"
import InputRadio from "components/Input/InputRadio"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { scrollTop, swapFormatedDate } from "helpers/utils_helper"
import DivText from "components/Input/DivText"

const TabPreviewFormEmploymentHistory = ({ dataDetail = null }) => {
  return (
    <React.Fragment>
      <Form>
        <h4 className="mb-3 font-size-18">{"EMPLOYMENT HISTORY"}</h4>
        <Row>
          <Col md={6}>
            {/* Name of Institution */}
            <div className="mb-3">
              <DivText
                label="Name of Institution"
                content={dataDetail?.r07_institution_a || "-"}
              />
            </div>

            {/* Full Address */}
            <div className="mb-3">
              <DivText
                label="Full Address"
                content={dataDetail?.r07_address_a || "-"}
              />
            </div>

            {/* Telephone Number */}
            <div className="mb-3">
              <DivText
                label="Telephone Number"
                content={dataDetail?.r07_phone_a || "-"}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Position Title */}
            <div className="mb-3">
              <DivText
                label="Position Title"
                content={dataDetail?.r07_title_a || "-"}
              />
            </div>

            {/* Start Date */}
            <div className="mb-3">
              <DivText
                label="Start Date"
                content={dataDetail?.r07_date_start_a || "-"}
              />
            </div>

            {/* End Date */}
            <div className="mb-3">
              <DivText
                label="End Date"
                content={dataDetail?.r07_date_end_a || "-"}
              />
            </div>
          </Col>
        </Row>

        <div
          className={dataDetail?.r07_category_a === "other" ? "mb-0" : "mb-3"}
        >
          <DivText
            label="Employment Category?"
            content={dataDetail?.r07_category_a || "-"}
            mb="2px"
          />
        </div>

        {dataDetail?.r07_category_a === "other" && (
          <div className="mb-3">{dataDetail?.r07_other_a || "-"}</div>
        )}

        <div className="mb-3">
          <DivText
            label="Describe your job duties and accomplishments. Comment on supervisory responsibilities, as appropriate."
            content={dataDetail?.r07_job_description_a || "-"}
          />
        </div>

        <hr />

        <Row>
          <Col md={6}>
            {/* Name of Institution */}
            <div className="mb-3">
              <DivText
                label="Name of Institution"
                content={dataDetail?.r07_institution_b || "-"}
              />
            </div>

            {/* Full Address */}
            <div className="mb-3">
              <DivText
                label="Full Address"
                content={dataDetail?.r07_address_b || "-"}
              />
            </div>

            {/* Telephone Number */}
            <div className="mb-3">
              <DivText
                label="Telephone Number"
                content={dataDetail?.r07_phone_b || "-"}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Position Title */}
            <div className="mb-3">
              <DivText
                label="Position Title"
                content={dataDetail?.r07_title_b || "-"}
              />
            </div>

            {/* Start Date */}
            <div className="mb-3">
              <DivText
                label="Start Date"
                content={dataDetail?.r07_date_start_b || "-"}
              />
            </div>

            {/* End Date */}
            <div className="mb-3">
              <DivText
                label="End Date"
                content={dataDetail?.r07_date_end_b || "-"}
              />
            </div>
          </Col>
        </Row>

        <div
          className={dataDetail?.r07_category_b === "other" ? "mb-0" : "mb-3"}
        >
          <DivText
            label="Employment Category?"
            content={dataDetail?.r07_category_b || "-"}
            mb="2px"
          />
        </div>

        {dataDetail?.r07_category_b === "other" && (
          <div className="mb-3">{dataDetail?.r07_other_b || ""}</div>
        )}

        <div className="mb-3">
          <DivText
            label="Describe your job duties and accomplishments. Comment on supervisory responsibilities, as appropriate."
            content={dataDetail?.r07_job_description_b || "-"}
          />
        </div>

        <hr />
      </Form>
    </React.Fragment>
  )
}

export default TabPreviewFormEmploymentHistory
