/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputFile from "components/Input/InputFile"
import InputSingleSelect from "components/Input/InputSingleSelect"
import InputDateMask from "components/Input/InputDateMask"
import InputTextArea from "components/Input/InputTextArea"
import InputEmail from "components/Input/InputEmail"
import InputNumber from "components/Input/InputNumber"
import InputCheckbox from "components/Input/InputCheckbox"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { canSubmitRegister, scrollTop } from "helpers/utils_helper"
import InputRadio from "components/Input/InputRadio"

const TabFormEnglishLanguageProficiency = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
  reset = false,
  onResetFalse = () => {},
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (reset) {
      setError("")
      onResetFalse()
    }
  }, [reset])

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      test_type: dataDetail?.r04_test_type || "TOEFL ITP",
      test_date: dataDetail !== null && dataDetail?.r04_test_date !== "0000-00-00" ? dataDetail?.r04_test_date : "",
      test_score: dataDetail?.r04_test_score || "",
      test_location: dataDetail?.r04_test_location || "",
    },

    onSubmit: values => {
      values = {
        ...values,
        test_date: values.test_date
      }
      
      if (canSubmitRegister(dataDetail?.status)) {
        if (!loading) postBeasiswaRegister(values)
      } else {
        onNext()
      }
    },
  })

  const postBeasiswaRegister = async data => {
    try {
      setLoading(true)
      const response = await postAPIBeasiswaRegister(data, "04")
      setLoading(false)
      if (response.status === "SUCCESS") {
        onSuccess(response.message)
        onNext()
      } else {
        scrollTop()
        setError(response.message)
        
      }
    } catch (error) {
      setLoading(false)
      scrollTop()
      setError("Something went wrong, please try again")
     
    }
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <p className="card-title-desc">
          Please check (v) only one box below and attach a copy of the current
          official test results to this application.
        </p>

        <Row>
          <Col md={6}>
            {/* Type of test */}
            <div className="mb-3">
              <InputRadio
                name="test_type"
                label="Type of test"
                value={_formik.values.test_type || ""}
                onChange={_formik.handleChange}
                options={[
                  {
                    label: "TOEFL ITP",
                    value: "TOEFL ITP",
                  },
                  {
                    label: "TOEFL iBT",
                    value: "TOEFL iBT",
                  },
                  {
                    label: "IELTS",
                    value: "IELTS",
                  }
                ]}
              />
            </div>

            {/* DATE TEST TAKEN */}
            <div className="mb-3">
              <InputDateMask
                mask="99/99/9999"
                label="Date Test Taken"
                name="test_date"
                placeholder="Date Test Taken (DD/MM/YYYY)"
                value={_formik.values.test_date || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* OVERALL SCORE */}
            <div className="mb-3">
              <InputNumber
                label="Overall Score"
                name="test_score"
                placeholder="Overall Score"
                value={_formik.values.test_score || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>

            {/* TEST LOCATION */}
            <div className="mb-3">
              <InputText
                label="Test Location"
                name="test_location"
                placeholder="Test Location"
                value={_formik.values.test_location || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
              />
            </div>
          </Col>
        </Row>

        <hr />

        <div className="d-flex justify-content-end">
          {onPrev && (
            <Link
              to="#"
              className={`btn btn-primary btn-block me-2 ${loading ? 'disabled' : ''}`}
              onClick={onPrev}
            >
              Previous
            </Link>
          )}

          <button className="btn btn-primary btn-block" type="submit">
            {loading ? <Spinner size="sm" /> : "Next"}
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default TabFormEnglishLanguageProficiency
