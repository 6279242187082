import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import parse from "html-react-parser"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

import maintenance from "../../assets/images/maintenance.svg"

const UnderConstruction = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Under Construction | Beasiswa BUMN</title>
        </MetaTags>
        <Container fluid={true} className="center">
          <React.Fragment>
            <h4 className="mb-0 font-size-18">Page is Under Construction</h4>
            <p>Please come back later.</p>
            <Row className="justify-content-center">
              <Col>
                <Card>
                  <CardBody>
                    <div className="maintenance-img">
                      <img
                        src={maintenance}
                        alt=""
                        className="col-5 img-fluid mx-auto d-block"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UnderConstruction
