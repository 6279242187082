/* eslint-disable react/prop-types */
import React, { useState } from "react"
import { Input, FormFeedback, Label } from "reactstrap"

const InputNumber = ({
  label,
  name,
  placeholder,
  value,
  invalid,
  errorMsg,
  infoMsg,
  onChange = () => {},
  onBlur = () => {},
}) => {
  return (
    <React.Fragment>
      <Label className="form-label">{label}</Label>
      <Input
        className="form-control"
        type="number"
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        invalid={invalid}
      />
      {infoMsg && <small className="form-text text-muted">{infoMsg}</small>}
      {invalid ? <FormFeedback type="invalid">{errorMsg}</FormFeedback> : null}
    </React.Fragment>
  )
}

export default InputNumber
