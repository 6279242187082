/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputFile from "components/Input/InputFile"
import InputSingleSelect from "components/Input/InputSingleSelect"
import InputDateMask from "components/Input/InputDateMask"
import InputTextArea from "components/Input/InputTextArea"
import InputEmail from "components/Input/InputEmail"
import InputNumber from "components/Input/InputNumber"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { scrollTop } from "helpers/utils_helper"
import DivText from "components/Input/DivText"

const TabPreviewFormEssays = ({ dataDetail = null }) => {
  return (
    <React.Fragment>
      <Form>
        <h4 className="mb-3 font-size-18">{"ESSAYS"}</h4>

        {/* A */}
        <div className="mb-3">
          <DivText
            label="a. Briefly tell Yayasan BUMN about yourself and comment on your perception of your strengths and weaknesses."
            content={dataDetail?.r13_essay_a || "-"}
          />
        </div>

        {/* B */}
        <div className="mb-3">
          <DivText
            label="b. What challenges have you overcome in pursuing your professional development? What insights, perspectives, or skills have you gained in overcoming them?"
            content={dataDetail?.r13_essay_b || "-"}
          />
        </div>

        {/* C */}
        <div className="mb-3">
          <DivText
            label="c. Please describe an example where you demonstrated leadership skills in your work life or within your community."
            content={dataDetail?.r13_essay_c || "-"}
          />
        </div>

        {/* D */}
        <div className="mb-3">
          <DivText
            label="d. Students face many challenges. Please cite two anticipated challenges that most concern you about studying abroad and explain how you plan to address the challenges."
            content={dataDetail?.r13_essay_d || "-"}
          />
        </div>

        {/* E */}
        <div className="mb-3">
          <DivText
            label="e. Discuss a topic that you are considering for further research during your Master's studies."
            content={dataDetail?.r13_essay_e || "-"}
          />
        </div>

        {/* F */}
        <div className="mb-3">
          <DivText
            label="f. Discuss the most important current issues in your professional field. You may choose to answer in a general sense or focus on one or more issues that apply most directly to the context in which you work. Response to this question may be 250 words or less."
            content={dataDetail?.r13_essay_f || "-"}
          />
        </div>

        <hr />
      </Form>
    </React.Fragment>
  )
}

export default TabPreviewFormEssays
