import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ValidationEmailPage from "../pages/Authentication/validation-email"


// Dashboard
import Dashboard from "../pages/Dashboard/index"
import FormPengajuanBeasiswa from "pages/RegistrasiBeasiswa/FormPengajuanBeasiswa/form-pengajuan-beasiswa"
import StatusPengajuanBeasiswa from "pages/RegistrasiBeasiswa/StatusPengajuanBeasiswa/status-pangajuan-beasiswa-page"
import UnderConstruction from "pages/UnderConstruction/under-construction-page"

const authProtectedRoutes = [
  { path: "/home", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },

  // Register Beasiswa
  { path: "/status-pengajuan-beasiswa", component: StatusPengajuanBeasiswa },
  { path: "/form-pengajuan-beasiswa", component: FormPengajuanBeasiswa },
  { path: "/under-construction", component: UnderConstruction },
  
  

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/status-pengajuan-beasiswa" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/validation/:code", component: ValidationEmailPage},
]

export { publicRoutes, authProtectedRoutes }
