/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputDateMask from "components/Input/InputDateMask"
import InputTextArea from "components/Input/InputTextArea"
import InputNumber from "components/Input/InputNumber"
import InputRadio from "components/Input/InputRadio"
import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { scrollTop } from "helpers/utils_helper"
import DivText from "components/Input/DivText"

const TabPreviewFormScholarshipHistory = ({ dataDetail = null }) => {
  return (
    <React.Fragment>
      <Form>
        <h4 className="mb-3 font-size-18">{"SCHOLARSHIP HISTORY"}</h4>
        <div className="mb-3">
          <DivText
            label="Have you ever received any scholarship or other educational funding support from any institution?"
            content={dataDetail?.r06_receive_funding || "-"}
          />
        </div>

        <Row>
          <Col md={6}>
            {/* SCHOLARSHIP TITLE */}
            <div className="mb-3">
              <DivText
                label="Scholarship Title"
                content={dataDetail?.r06_scholarship_title || "-"}
              />
            </div>

            {/* SCHOLARSHIP Provider */}
            <div className="mb-3">
              <DivText
                label="Scholarship Provider"
                content={dataDetail?.r06_scholarship_provider || "-"}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* AWARD DATE AND DURATION*/}
            <div className="mb-3">
              <div className="mb-3">
                <DivText
                  label="Award Date and Duration"
                  content={dataDetail?.r06_award_duration || "-"}
                />
              </div>
            </div>

            {/* CONTACT PERSON */}
            <div className="mb-3">
              <DivText
                label="Contact Person"
                content={dataDetail?.r06_contact_person || "-"}
              />
            </div>
          </Col>
        </Row>

        {/* DESCRIBE THE SCHOLARSHIP */}
        <div className="mb-3">
          <DivText
            label="Describe the scholarship and comment on the type/purpose; full or partial funding; what level of education; degree or non-degree studies"
            content={dataDetail?.r06_description || "-"}
          />
        </div>

        <hr />
      </Form>
    </React.Fragment>
  )
}

export default TabPreviewFormScholarshipHistory
